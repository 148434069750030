(function () {
    'use strict';

    angular
        .module('stpApp')
        .directive('routePlanningTerritory', routePlanningTerritory);

    routePlanningTerritory.$inject = ['Route', 'RoutePlanningConstants', 'RoutePlanningModalFactory'];

    function routePlanningTerritory(Route, RoutePlanningConstants, RoutePlanningModalFactory) {
        var directive = {
            restrict: 'E',
            scope: true,
            templateUrl: 'app/route/directives/route-planning-territory.html',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            var vm = scope.vm;
            var CONST = RoutePlanningConstants;
            vm.isAllStopTerritorytHide = false;

            vm.capacityTemp = null;
            vm.toggleCapacityAndSetTemp = toggleCapacityAndSetTemp;

            vm.$onDestroy = $onDestroy;

            vm.onShowHideAllStopsTerritory = onShowHideAllStopsTerritory;
            vm.onShowHideStopsTerritory = onShowHideStopsTerritory;
            vm.getTerritorySize = getTerritorySize;
            vm.onUpdateCapacity = onUpdateCapacity;
            vm.openCalculateTerritoryDialog = openCalculateTerritoryDialog;
            vm.increaseTerritory = increaseTerritory;
            vm.decreaseTerritory = decreaseTerritory;
            vm.showTerritoryInputToggle = false;
            vm.numberOfNewTerritory = 1;
            vm.addMultipleTerritory = addMultipleTerritory;

            scope.$watch('vm.sharedData.selectedRoute', function (newVal, oldVal) {
                if (newVal && newVal.size > 0) {
                    vm.showTerritoryInputToggle = false;
                    vm.numberOfNewTerritory = 1;
                }
            });

            function $onDestroy() {
                vm.changeView();
            }

            function onShowHideAllStopsTerritory(showHide) {
                for (var item in vm.toggleLayer) {
                    if (Object.hasOwnProperty.call(vm.toggleLayer, item)) {
                        vm.toggleLayer[item] = showHide;
                    }
                }
                if (!showHide) {
                    vm.isAllStopTerritorytHide = true;
                }else {
                    vm.isAllStopTerritorytHide = false;
                }
                vm.onToggleLayerChange();
            }

            function onShowHideStopsTerritory() {
                var stops = Object.entries(vm.toggleLayer);
                var allStop = stops.every(function (stop) { return stop[1]; });
                if (allStop) {
                    vm.isAllStopTerritorytHide = false;
                }else{
                    vm.isAllStopTerritorytHide = true;
                }
            }

            function getTerritorySize() {
                if (!vm.getDataStyles()) {
                    return 0;
                }

                var activeDepot = vm.getFilters().depot;
                var styleByDepot = vm.getDataStyles().depots[activeDepot];

                if (activeDepot && styleByDepot && styleByDepot.hasOwnProperty('territories')) {
                    return Object.keys(styleByDepot.territories).length;
                }
                return 0;
            }

            function toggleCapacityAndSetTemp(key, capacity) {
                vm.capacityTemp = capacity ? angular.copy(capacity) : 0;
                vm.isEditCapacity = {};
                vm.isEditCapacity[key] = true;
            }

            function onUpdateCapacity($event, key, capacity) {
                // when enter keypress
                if($event.keyCode === 13) {
                    vm.isEditCapacity[key] = false;
                    vm.updateProp();
                    vm.loadProjectErrorSummary(true);
                }

                // when escape keypress
                if($event.keyCode === 27) {
                    vm.isEditCapacity[key] = false;
                    vm.getDataStyles().depots[vm.getFilters().depot].territories[key].loadCapacity = vm.capacityTemp;
                    vm.capacityTemp = null;
                }
            }

            function openCalculateTerritoryDialog() {

                var territoryCalculatorDialog = RoutePlanningModalFactory.territoryCalculatorDialog(vm.project, vm.mapProp);
                territoryCalculatorDialog.result.then(function(res){
                    if(res === 'reload') {
                        vm.reloadRoutePlanCore();
                    }
                });
            }

            function increaseTerritory() {
                vm.numberOfNewTerritory += 1;
            }

            function decreaseTerritory() {
                vm.numberOfNewTerritory -= 1;
            }

            function addMultipleTerritory() {
                vm.disableOnLoad = true;
                vm.showTerritoryInputToggle = false;

                var teritory = Object.keys(vm.sharedData.dataStyle.depots[vm.sharedData.filters.depot].territories);
                var startIndex = teritory.length ? parseInt(teritory[teritory.length - 1]) + 1 : 1;
                var backupStyle = angular.copy(vm.sharedData.dataStyle);

                for (var i = 0; i < vm.numberOfNewTerritory; i++) {
                    vm.sharedData.dataStyle[CONST.DEPOTS][vm.sharedData.filters.depot]['territories'][
                        startIndex
                    ] = {};
                    vm.sharedData.dataStyle[CONST.DEPOTS][vm.sharedData.filters.depot]['territories'][
                        startIndex
                    ].color = vm.colors[startIndex];
                    startIndex += 1;
                }

                var updatePp = vm.updateProp();
                updatePp.$promise.then(function () {
                    vm.isLoadingStatisticData = true;
                    vm.numberOfNewTerritory = 1;
                    vm.reloadRoutePlanCore();
                }, function(){
                    vm.disableOnLoad = false;
                    vm.isLoadingStatisticData = false;
                    vm.sharedData.dataStyle = backupStyle;
                });
            }
        }
    }

})();
