(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('WorkplaceDialogController', WorkplaceDialogController);

    WorkplaceDialogController.$inject = ['$timeout', '$uibModalInstance', 'entity', 'Workplace', 'User'];

    function WorkplaceDialogController ($timeout, $uibModalInstance, entity, Workplace, User) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;
        vm.validateUserLimit = validateUserLimit;

        vm.users = [];
        vm.workplace = entity;
        vm.userOverLimit = 0;
        vm.loadUserError = false;
        vm.loadingUser = false;

        // jika table sudah pernah muncul tidak perlu di set lagi
        vm.showUserTable = false;

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        init();

        function init() {
            vm.workplace.validUntil.setSeconds(0);
            vm.workplace.validUntil.setMilliseconds(0);

            if (vm.workplace.id && vm.workplace.activeUserAccountLimit < vm.workplace.totalOfActiveUsers) {
                loadUsers();
            }
        }

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            if (vm.workplace.id !== null) {
                vm.workplace.deactivatedUsers = vm.users.filter(function (u) {
                    return !u.activated;
                });

                Workplace.update({id: vm.workplace.id},vm.workplace, onSaveSuccess, onSaveError);
            } else {
                Workplace.save(vm.workplace, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function loadUsers() {
            vm.showUserTable = true;
            vm.loadingUser = true;

            var params = {
                size: 2000,
                page: 0,
                sort: ['id,asc'],
                'workplaceId.equals': vm.workplace.id,
                'activated.equals': true
            };

            User.query(params, onLoadUserSuccess, onLoadUserError);
        }

        function onLoadUserSuccess(data) {
            vm.users = data;
            vm.loadingUser = false;
            validateUserLimit();
        }

        function onLoadUserError() {
            vm.loadingUser = false;
            vm.loadUserError = true;
        }

        function validateUserLimit() {
            if (vm.loadingUser || !vm.workplace.id) {
                return;
            }

            if (!vm.users.length && vm.workplace.activeUserAccountLimit < vm.workplace.totalOfActiveUsers) {
                loadUsers();
                return;
            }

            if (vm.workplace.activeUserAccountLimit < 0) {
                vm.userOverLimit = 0;
                return;
            }

            var activeUsers = vm.users.filter(function (u) {
                return u.activated;
            });

            vm.userOverLimit =  activeUsers.length - vm.workplace.activeUserAccountLimit;
        }
    }
})();
