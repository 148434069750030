(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', '$state', 'Auth', 'Uuid', 'LoginFactory', '$rootScope', '$timeout'];

    function HomeController ($scope, Principal, $state, Auth, Uuid, LoginFactory, $rootScope, $timeout) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.authenticationError = false;
        vm.workplaceExpired = false;
        vm.password = null;
        vm.username = null;
        vm.loading = false;

        vm.login = login;

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (vm.isAuthenticated()) {
                    var isOneTimeUser = vm.account.authorities.find(function (role) {
                        return role === 'ROLE_ONE_TIME_USER';
                    });
                    if (!isOneTimeUser) {
                        $state.go('project');
                    }
                }
            });
        }

        function login () {
            vm.loading = true;

            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe,
                concurrentId: Uuid.uuidv4()
            }).then(function () {
                vm.authenticationError = false;
                vm.workplaceExpired = false;
                vm.loading = false;

                LoginFactory.validationUser();
                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home');
                }

                $rootScope.$broadcast('authenticationSuccess');

                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }
            }).catch(function (e) {
                var isExpired = e.data.detail ? e.data.detail : '';
                if (isExpired.includes('expired')) {
                    vm.workplaceExpired = true;
                } else {
                    vm.authenticationError = true;
                }
                vm.loading = false;
            });
        }
    }
})();
