(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Principal', 'User', 'ParseLinks', 'AlertService', '$state', 'pagingParams', 'paginationConstants', 'JhiLanguageService', 'workplaces', '$uibModal'];

    function UserManagementController(Principal, User, ParseLinks, AlertService, $state, pagingParams, paginationConstants, JhiLanguageService, workplaces, $uibModal) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
        vm.currentAccount = null;
        vm.languages = null;
        vm.loadAll = loadAll;
        vm.setActive = setActive;
        vm.users = [];
        vm.page = 1;
        vm.totalItems = null;
        vm.clear = clear;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.clearSearch = clearSearch;
        vm.search = search;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.transition = transition;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.workplaces = workplaces;
        vm.isCheckAll = false;
        vm.isCheckNone = false;
        vm.isIndeterminate = false;
        vm.checkAll = checkAll;
        vm.checkNone = checkNone;
        vm.checkOne = checkOne;
        vm.checkedLabel = checkedLabel;
        vm.dropdownToggleChange = dropdownToggleChange;
        vm.workplaceParams = pagingParams.workplace;
        vm.isLoading = true;
        vm.warningDialogAlreadyOpen = false;

        setWorkplaceState();
        vm.loadAll();

        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });
        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });

        function setActive(user, isActivated) {
            if (user.isActivating) {
                return;
            }

            user.isActivating = true;

            // copy user
            var copyOfUser = angular.copy(user);
            copyOfUser.activated = isActivated;

            User.update(
                copyOfUser,
                function (data) {
                    user.activated = data.activated;
                    user.isActivating = false;
                },
                function (e) {
                    user.isActivating = false;
                    openWorkplaceLimitDialog(user);
                }
            );
        }

        function loadAll () {
            var params = {
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            };

            if (pagingParams.search) {
                params["login.contains"] = pagingParams.search;
            }

            if (pagingParams.workplace && pagingParams.workplace !== 'ALL') {
                if (pagingParams.workplace === 'NONE') {
                    params["workplaceId.specified"] = false;
                } else {
                    params["workplaceId.in"] = pagingParams.workplace;
                }
            }

            vm.isLoading = true;
            User.query(params, onSuccess, onError);
        }

        function onSuccess(data, headers) {
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = headers('X-Total-Count');
            vm.queryCount = vm.totalItems;
            vm.page = pagingParams.page;
            vm.users = data;
            vm.isLoading = false;
        }

        function onError(error) {
            vm.isLoading = false;
            AlertService.error(error.data.message);
        }

        function clear () {
            vm.user = {
                id: null, login: null, firstName: null, lastName: null, email: null,
                activated: null, langKey: null, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: null
            };
        }

        function sort () {
            var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
                workplace: getWorkplaceParam(),
            });
        }

        function search (searchQuery) {
            if (!searchQuery) {
                return vm.clearSearch();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clearSearch () {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }

        function checkAll() {
            vm.isIndeterminate = false;

            vm.workplaces.forEach(function(wp) {
                wp.checked = vm.isCheckAll;
            });

            vm.isCheckNone = vm.workplaces.every(function(wp){
                return !wp.checked;
            });
        }

        function checkOne() {
            vm.isCheckAll = vm.workplaces.every(function(wp){
                return wp.checked;
            });

            vm.isCheckNone = vm.workplaces.every(function(wp){
                return !wp.checked;
            });

            vm.isIndeterminate = !vm.isCheckAll && !vm.isCheckNone;
        }

        function checkNone() {
            vm.isIndeterminate = false;
            vm.isCheckAll = false;

            vm.workplaces.forEach(function(wp) {
                wp.checked = false;
            });
        }

        function checkedLabel() {
            var numOfSelectedWorkplace = vm.workplaces.filter(function(wp) {
                return wp.checked;
            });

            if (vm.isCheckAll) {
                return 'All workplaces';
            } else if(vm.isCheckNone) {
                return 'No workplace';
            } else if(numOfSelectedWorkplace.length > 1) {
                return numOfSelectedWorkplace.length + ' workplaces checked';
            } else if(numOfSelectedWorkplace.length === 1){
                return numOfSelectedWorkplace[0].name;
            } else {
                return 'Select workplace';
            }
        }

        function dropdownToggleChange(status) {
            if (!status) {
                vm.isLoading = true;
                vm.transition();
            }
        }

        function getWorkplaceParam() {
            var checked = vm.workplaces.filter(function(wp) {
                return wp.checked;
            });

            if (vm.isCheckAll) {
                return 'ALL';
            } else if(vm.isCheckNone) {
                return 'NONE';
            } else {
                return checked.map(function(c) {
                    return c.id;
                }).join(',');
            }
        }

        function setWorkplaceState() {
            if (vm.workplaceParams === 'ALL' || !vm.workplaceParams) {
                vm.isCheckAll = true;
                vm.workplaces.forEach(function (wp) {
                    wp.checked = true;
                });
            } else if(vm.workplaceParams === 'NONE') {
                vm.isCheckNone = true;
                vm.isCheckAll = false;
                vm.workplaces.forEach(function (wp) {
                    wp.checked = false;
                });
            } else {
                vm.isIndeterminate = true;
                var checkedId = vm.workplaceParams.split(",");
                checkedId.forEach(function (cId) {
                    var findIdx = vm.workplaces.findIndex(function(wp) {
                        return wp.id === Number(cId);
                    });

                    if(findIdx > -1) {
                        vm.workplaces[findIdx].checked = true;
                    }
                });
            }
        }

        function openWorkplaceLimitDialog(user) {
            if (vm.warningDialogAlreadyOpen) {
                return;
            }

            $uibModal.open({
                templateUrl: 'app/admin/user-management/user-management-workplace-limit-dialog.controller.html',
                controller: 'UserManagementWorkplaceLimitController',
                controllerAs: 'vm',
                size: 'lg',
                resolve: {
                    workplaceLimit: user.workplace.activeUserAccountLimit
                }
            }).result.then(function() {
                vm.warningDialogAlreadyOpen = false;
            }, function() {
                vm.warningDialogAlreadyOpen = false;
            });

            vm.warningDialogAlreadyOpen = true;
        }
    }
})();
