(function(){
    'use strict';

    angular
        .module('stpApp')
        .controller('AutoBalanceDialogController', AutoBalanceDialogController);

    AutoBalanceDialogController.$inject = ['sharedData', '$uibModalInstance', 'Route', 'RoutePlanningConstants', '$q', 'AlertService'];

    function AutoBalanceDialogController(sharedData, $uibModalInstance, Route, RoutePlanningConstants, $q, AlertService) {
        var vm = this;
        var CONST = RoutePlanningConstants;
        var projectId = sharedData.depots[0].projectId;

        vm.sharedData = sharedData;
        vm.option1 = {
            key: 'STOPCOUNT',
        };
        vm.option2 = {
            key: 'BALANCEDEPOTRADIUS',
        };
        vm.option3 = {
            key: 'VALUE',
            selectedValue: 'value'
        };
        vm.option4 = {
            key: 'VALUE',
            selectedValue: 'value2'
        };
        vm.option5 = {
            key: 'VALUE',
            selectedValue: 'value3'
        };
        vm.isSaving = false;
        vm.type = ''; // depot, territory, week, day
        vm.autoBalanceMode = 'MINIMUM_TRANSPORT_COST'; // MINIMUM_TRANSPORT_COST, PIZZA, DISTANCE
        vm.autoBalanceBasedOn = vm.option1;
        vm.clusterStopBy = 'NONE'; // NONE, DISTRICT, URBAN_VILLAGE

        vm.close = close;
        vm.startAutobalance = startAutobalance;
        vm.balanceWeights = [];
        vm.showBalanceWeights = false;
        vm.balanceWeightsInvalid = false;
        vm.dayWeightChange = dayWeightChange;

        /**
         * for icon switch. value: true, false
         */
        vm.switchObj = {
            territory: false,
            week: false,
            day: false,
        };
        vm.switchCollection = switchCollection;

        // ref: http://marceljuenemann.github.io/angular-drag-and-drop-lists/demo/#/simple
        vm.collectionTerritory = {
            length: 0,
            selected: null,
            lists: {"preserved": [], "unpreserved": []}
        };
        vm.collectionWeek = {
            length: 0,
            selected: null,
            lists: {"preserved": [], "unpreserved": []}
        };
        vm.collectionDay = {
            length: 0,
            selected: null,
            lists: {"preserved": [], "unpreserved": []}
        };

        init();

        /**
         * first load
         */
        function init() {
            vm.type = sharedData.currentView;
            _pushCollection();

            /**
             * push to collection each type
             */
            function _pushCollection() {
                if (sharedData.filters.depot) {
                    for (var keyTerritory in sharedData.dataStyle.depots[sharedData.filters.depot].territories) {
                        if (Object.hasOwnProperty.call(sharedData.dataStyle.depots[sharedData.filters.depot].territories, keyTerritory)) {
                            vm.collectionTerritory.lists.unpreserved.push({id: parseInt(keyTerritory), label: "Territory " + keyTerritory});
                        }
                    }
                    for (var keyWeek in sharedData.dataStyle.depots[sharedData.filters.depot].weeks) {
                        if (Object.hasOwnProperty.call(sharedData.dataStyle.depots[sharedData.filters.depot].weeks, keyWeek)) {
                            vm.collectionWeek.lists.unpreserved.push({id: parseInt(keyWeek), label: "Week " + keyWeek});
                        }
                    }
                    for (var day in sharedData.dataStyle.days) {
                        if (Object.hasOwnProperty.call(sharedData.dataStyle.days, day)) {
                            vm.collectionDay.lists.unpreserved.push({id: parseInt(day), label: "Day " + day});
                            vm.balanceWeights.push({day: day, value: 100, error: false});
                        }
                    }

                    // if selected territory/week/day from sidebar, `auto prevered`
                    if (sharedData.filters.territory) {
                        var daySelected = sharedData.filters.territory.split(',').map(function (x) { return parseInt(x); });
                        var preserved = vm.collectionTerritory.lists.unpreserved.filter(function (val) { return daySelected.includes(val.id); });
                        var unpreserved = vm.collectionTerritory.lists.unpreserved.filter(function (val) { return !daySelected.includes(val.id); });

                        vm.collectionTerritory.lists.preserved = preserved;
                        vm.collectionTerritory.lists.unpreserved = unpreserved;
                    }

                    if (sharedData.filters.week) {
                        daySelected = sharedData.filters.week.split(',').map(function (x) { return parseInt(x); });
                        preserved = vm.collectionWeek.lists.unpreserved.filter(function (val) { return daySelected.includes(val.id); });
                        unpreserved = vm.collectionWeek.lists.unpreserved.filter(function (val) { return !daySelected.includes(val.id); });

                        vm.collectionWeek.lists.preserved = preserved;
                        vm.collectionWeek.lists.unpreserved = unpreserved;
                    }

                    if (sharedData.filters.day) {
                        daySelected = sharedData.filters.day.split(',').map(function (x) { return parseInt(x); });
                        preserved = vm.collectionDay.lists.unpreserved.filter(function (val) { return daySelected.includes(val.id); });
                        unpreserved = vm.collectionDay.lists.unpreserved.filter(function (val) { return !daySelected.includes(val.id); });

                        vm.collectionDay.lists.preserved = preserved;
                        vm.collectionDay.lists.unpreserved = unpreserved;
                    }

                    vm.collectionTerritory.length = Object.keys(sharedData.dataStyle.depots[sharedData.filters.depot][CONST.TERRITORIES]).length;
                    vm.collectionWeek.length = Object.keys(sharedData.dataStyle.depots[sharedData.filters.depot][CONST.WEEKS]).length;
                    vm.collectionDay.length = Object.keys(sharedData.dataStyle[CONST.DAYS]).length;
                }
            }
        }

        /**
         * close modal
         */
        function close() {
            $uibModalInstance.dismiss();
        }

        /**
         * switch collection between preserved & unpreserved
         * @param {string} type territory, week, day
         */
        function switchCollection(type) {
            if (vm.isSaving) {
                return true;
            }

            switch (type) {
            case 'territory':
                vm.switchObj.territory = !vm.switchObj.territory;

                var preserved = vm.collectionTerritory.lists.preserved;
                var unpreserved = vm.collectionTerritory.lists.unpreserved;

                vm.collectionTerritory.lists.preserved = [];
                vm.collectionTerritory.lists.unpreserved = [];

                if (vm.switchObj.territory) {
                    preserved.forEach(function (item) {
                        vm.collectionTerritory.lists.preserved.push(item);
                    });
                    unpreserved.forEach(function (item) {
                        vm.collectionTerritory.lists.preserved.push(item);
                    });
                } else {
                    preserved.forEach(function (item) {
                        vm.collectionTerritory.lists.unpreserved.push(item);
                    });
                    unpreserved.forEach(function (item) {
                        vm.collectionTerritory.lists.unpreserved.push(item);
                    });
                }
                break;

            case 'week':
                vm.switchObj.week = !vm.switchObj.week;

                preserved = vm.collectionWeek.lists.preserved;
                unpreserved = vm.collectionWeek.lists.unpreserved;

                vm.collectionWeek.lists.preserved = [];
                vm.collectionWeek.lists.unpreserved = [];

                if (vm.switchObj.week) {
                    preserved.forEach(function (item) {
                        vm.collectionWeek.lists.preserved.push(item);
                    });
                    unpreserved.forEach(function (item) {
                        vm.collectionWeek.lists.preserved.push(item);
                    });
                } else {
                    preserved.forEach(function (item) {
                        vm.collectionWeek.lists.unpreserved.push(item);
                    });
                    unpreserved.forEach(function (item) {
                        vm.collectionWeek.lists.unpreserved.push(item);
                    });
                }
                break;

            case 'day':
                vm.switchObj.day = !vm.switchObj.day;

                preserved = vm.collectionDay.lists.preserved;
                unpreserved = vm.collectionDay.lists.unpreserved;

                vm.collectionDay.lists.preserved = [];
                vm.collectionDay.lists.unpreserved = [];

                if (vm.switchObj.day) {
                    preserved.forEach(function (item) {
                        vm.collectionDay.lists.preserved.push(item);
                    });
                    unpreserved.forEach(function (item) {
                        vm.collectionDay.lists.preserved.push(item);
                    });
                } else {
                    preserved.forEach(function (item) {
                        vm.collectionDay.lists.unpreserved.push(item);
                    });
                    unpreserved.forEach(function (item) {
                        vm.collectionDay.lists.unpreserved.push(item);
                    });
                }
                break;

            default:
                break;
            }
        }

        /**
         * start auto balance (save)
         */
        function startAutobalance() {
            vm.isSaving = true;

            var territoryPreserved = vm.collectionTerritory.lists.preserved;
            var weekPreserved = vm.collectionWeek.lists.preserved;
            var dayPreserved = vm.collectionDay.lists.preserved;

            var bodyRequest = {
                balancingBy: vm.autoBalanceBasedOn.key,
                depotBalancingFactor: 0,
            };

            if (vm.autoBalanceBasedOn.key === 'VALUE') {
                bodyRequest = Object.assign(bodyRequest, {selectedValue: vm.autoBalanceBasedOn.selectedValue});
            }

            var queryString = {
                "projectId.equals": projectId
            };

            var requests = [];
            var params = [];

            switch (vm.type) {
            case 'territory':
                queryString["depotId.equals"] = sharedData.filters.depot;

                bodyRequest.size = Object.keys(sharedData.dataStyle.depots[sharedData.filters.depot][CONST.TERRITORIES]).length;
                bodyRequest.doNotRefreshDRS = true;
                bodyRequest.cluster = vm.clusterStopBy;

                switch (vm.autoBalanceMode) {
                case 'DISTANCE':
                    bodyRequest.type = 'DISTANCE';
                    break;

                case 'PIZZA':
                    bodyRequest.type = 'TERRITORY';
                    break;

                default:
                    bodyRequest.type = 'TERRITORY_ODL';
                    break;
                }

                if (weekPreserved.length && dayPreserved.length) {
                    for (var i = 0; i < weekPreserved.length; i++) {
                        for (var j = 0; j < dayPreserved.length; j++) {
                            queryString['week.in'] = weekPreserved[i].id;
                            queryString['day.in'] = dayPreserved[j].id;
                            var request = Route.balancingTerritory(queryString, bodyRequest).$promise;
                            requests.push(request);
                        }
                    }
                } else if(weekPreserved.length) {
                    for (i = 0; i < weekPreserved.length; i++) {
                        queryString['week.in'] = weekPreserved[i].id;
                        request = Route.balancingTerritory(queryString, bodyRequest).$promise;
                        requests.push(request);
                    }
                }else if(dayPreserved.length) {
                    for (j = 0; j < dayPreserved.length; j++) {
                        queryString['day.in'] = dayPreserved[j].id;
                        request = Route.balancingTerritory(queryString, bodyRequest).$promise;
                        requests.push(request);
                    }
                }else {
                    request = Route.balancingTerritory(queryString, bodyRequest).$promise;
                    requests.push(request);
                }

                break;
            case 'week':
                queryString["depotId.equals"] = sharedData.filters.depot;

                bodyRequest.size = Object.keys(sharedData.dataStyle.depots[sharedData.filters.depot][CONST.WEEKS]).length;
                bodyRequest.type = 'WEEK_ODL';

                if (territoryPreserved.length && dayPreserved.length) {
                    for (i = 0; i < dayPreserved.length; i++) {
                        var subReqWeek = [];
                        for (j = 0; j < territoryPreserved.length; j++) {
                            queryString['territory.in'] = territoryPreserved[j].id;
                            queryString['day.in'] = dayPreserved[i].id;
                            subReqWeek.push({query: angular.copy(queryString), body: bodyRequest});
                        }
                        params.push(subReqWeek);
                    }
                } else if(territoryPreserved.length) {
                    var subReqWeek2 = [];
                    for (i = 0; i < territoryPreserved.length; i++) {
                        queryString['territory.in'] = territoryPreserved[i].id;
                        subReqWeek2.push({query: angular.copy(queryString), body: bodyRequest});
                    }
                    params.push(subReqWeek2);
                } else if(dayPreserved.length) {
                    for (j = 0; j < dayPreserved.length; j++) {
                        queryString['day.in'] = dayPreserved[j].id;
                        params.push([{query: angular.copy(queryString), body: bodyRequest}]);
                    }
                } else {
                    params.push([{query: queryString, body: bodyRequest}]);
                }

                break;
            case 'day':
                queryString["depotId.equals"] = sharedData.filters.depot;

                bodyRequest.size = Object.keys(sharedData.dataStyle[CONST.DAYS]).length;
                bodyRequest.balancingWeights = vm.balanceWeights.reduce(function(config, bw){
                    if (!config) {
                        config = {};
                    }

                    config[bw.day] = bw.value;
                    return config;
                }, {});

                switch (vm.autoBalanceMode) {
                case 'DISTANCE':
                    bodyRequest.type = 'DISTANCE';
                    break;

                case 'PIZZA':
                    bodyRequest.type = 'TERRITORY';
                    break;

                default:
                    bodyRequest.type = 'DAY_ODL';
                    break;
                }

                if (territoryPreserved.length && weekPreserved.length) {
                    for (i = 0; i < weekPreserved.length; i++) {
                        var subReqDay = [];
                        for (j = 0; j < territoryPreserved.length; j++) {
                            queryString['territory.in'] = territoryPreserved[j].id;
                            queryString['week.in'] = weekPreserved[i].id;
                            subReqDay.push({query: angular.copy(queryString), body: bodyRequest});
                        }
                        params.push(subReqDay);
                    }
                } else if(territoryPreserved.length) {
                    var subReqDay2 = [];
                    for (i = 0; i < territoryPreserved.length; i++) {
                        queryString['territory.in'] = territoryPreserved[i].id;
                        subReqDay2.push({query: angular.copy(queryString), body: bodyRequest});
                    }
                    params.push(subReqDay2);
                } else if(weekPreserved.length) {
                    for (j = 0; j < weekPreserved.length; j++) {
                        queryString['week.in'] = weekPreserved[j].id;
                        params.push([{query: angular.copy(queryString), body: bodyRequest}]);
                    }
                } else {
                    params.push([{query: queryString, body: bodyRequest}]);
                }

                break;
            default:
                queryString = {
                    "projectId": projectId
                };
                bodyRequest = {
                    balancingBy: vm.autoBalanceBasedOn.key === 'BALANCEDEPOTRADIUS' ? 'STOPCOUNT' : vm.autoBalanceBasedOn.key,
                    depotBalancingFactor: vm.autoBalanceBasedOn.key === 'BALANCEDEPOTRADIUS' ? 100 : 0,
                    cluster: vm.clusterStopBy
                };
                if (vm.autoBalanceBasedOn.key === 'VALUE') {
                    bodyRequest = Object.assign(bodyRequest, {selectedValue: vm.autoBalanceBasedOn.selectedValue});
                }
                request = Route.balancingDepot(queryString, bodyRequest).$promise;
                requests.push(request);
                break;
            }

            if (vm.type === 'day') {
                _sequencialRequestForDay(0, params);
            } else if (vm.type === 'week'){
                _sequencialRequestForWeek(0, params);
            } else {
                $q.all(requests).then(_onSaveSuccess, _onSaveError);
            }

            function _onSaveSuccess() {
                vm.isSaving = false;
                $uibModalInstance.close(vm.type);
            }

            function _onSaveError(error) {
                vm.isSaving = false;
                if (error.status === 500) {
                    AlertService.error(error.statusText, 'error.internalServerError');
                } else {
                    AlertService.error(error.data.message);
                }
            }

            function _sequencialRequestForDay(index, params) {
                if (index < params.length) {
                    var request = params[index].map(function(p) {
                        return Route.balancingDay(p.query, p.body).$promise.catch(function(e){
                            if (e.data.message === 'error.balancing.nooutlets') {
                                return $q.resolve();
                            } else {
                                return $q.reject(e);
                            }
                        });
                    });
                    $q.all(request).then(
                        function(){
                            _sequencialRequestForDay(index + 1, params);
                            if(index === (params.length - 1)) {
                                _onSaveSuccess();
                            }
                        },
                        function(e){
                            _onSaveError(e);
                        }
                    );
                }
            }

            function _sequencialRequestForWeek(index, params) {
                if (index < params.length) {
                    var request = params[index].map(function(p) {
                        return Route.balancingWeek(p.query, p.body).$promise.catch(function(e){
                            if (e.data.message === 'error.balancing.nooutlets') {
                                return $q.resolve();
                            } else {
                                return $q.reject(e);
                            }
                        });
                    });
                    $q.all(request).then(
                        function(){
                            _sequencialRequestForWeek(index + 1, params);
                            if(index === (params.length - 1)) {
                                _onSaveSuccess();
                            }
                        },
                        function(e){
                            _onSaveError(e);
                        }
                    );
                }
            }
        }

        function dayWeightChange(index, value) {

            var isNumber = angular.isNumber(value);
            var isNumberDecimal = isDecimal(value);

            if (isNumberDecimal) {
                vm.balanceWeights[index].error = true;
            } else if (isNumber) {
                vm.balanceWeights[index].error = value >= 1 && value <= 100 ? false : true;
            } else {
                vm.balanceWeights[index].error = true;
            }

            vm.balanceWeightsInvalid = !vm.balanceWeights.every(function(bw){return bw.error === false;});
        }

        function isDecimal(val) {
            return val % 1 !== 0;
        }

    }
})();
