(function(){
    'use strict';

    angular
        .module('stpApp')
        .controller('BulkDeleteTerritoryDialogController', BulkDeleteTerritoryDialogController);

    BulkDeleteTerritoryDialogController.$inject = ['$uibModalInstance', 'territories', 'statistic', 'project', 'depot', 'Route'];

    function BulkDeleteTerritoryDialogController($uibModalInstance, territories, statistic, project, depot, Route) {
        var vm = this;
        vm.close = close;
        vm.onCheck = onCheck;
        vm.onCheckAll = onCheckAll;

        vm.step = 0; // 0 remove list, 1 transfer list
        vm.project = project;
        vm.depotId = depot;
        vm.territories = [];
        vm.transferlist = []; // territory yang ingin dihapus tapi memiliki stop
        vm.transferDestinationOpt = []; // territory yang tidak dihapus
        vm.checkAll = false;
        vm.isDeleting = false;
        vm.deleteAndTransfer = deleteAndTransfer;
        vm.nextStep = nextStep;
        vm.back = back;

        init();

        function close() {
            $uibModalInstance.dismiss();
        }

        function init() {
            vm.territories = Object.keys(territories).map(function(key) {
                var data = territories[key];
                data.id = Number(key); // Convert key to number
                data.checked = false;
                data.stopCount = statistic[key] ? statistic[key]['stop'] : 0;
                data.transfterTo = null;
                return data;
            });
        }

        function onCheck() {
            vm.checkAll = vm.territories.every(function(t) {return t.checked;});
            vm.transferDestinationOpt = vm.territories.filter(function(t){return !t.checked;});
        }

        function onCheckAll() {
            vm.territories.forEach(function(t){
                t.checked = vm.checkAll;
            });
            vm.transferDestinationOpt = vm.territories.filter(function(t){return !t.checked;});
        }

        function nextStep() {
            vm.transferlist = vm.territories.filter(function(t){return t.checked && t.stopCount;});

            // set default transfer destination
            vm.transferlist.forEach(function(tf) {
                tf.transfterTo = vm.transferDestinationOpt[0];
            });

            var isSomeHasStop = vm.transferlist.some(function(t){return t.stopCount;});

            if (isSomeHasStop) {
                vm.step = 1;
                return;
            } else {
                deleteAndTransfer();
            }
        }

        function back() {
            vm.step = 0;
            vm.transferlist = [];
        }

        function deleteAndTransfer() {
            vm.isDeleting = true;

            var deletedTerritories = vm.territories
                .filter(function (t) {
                    return t.checked;
                })
                .map(function (t) {
                    return t.id;
                });
            var stopTerritoryMoves = [];

            if (vm.step === 1) {
                stopTerritoryMoves = vm.transferlist
                    .map(function (t) {
                        var result = {
                            from: t.id,
                            to: t.transfterTo.id,
                        };

                        return result;
                    });
            }

            // do something here
            var deleteParameter = {
                projectId: vm.project.id,
                depotId: vm.depotId,
                deletedTerritories: deletedTerritories,
                stopTerritoryMoves: stopTerritoryMoves,
            };

            Route.bulkDelete(deleteParameter, function(){
                vm.isDeleting = false;
                $uibModalInstance.close();
            }, function(){
                vm.isDeleting = false;
            });
        }
    }
})();
