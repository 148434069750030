(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('WorkplaceDeleteController',WorkplaceDeleteController);

    WorkplaceDeleteController.$inject = ['$uibModalInstance', 'entity', 'Workplace'];

    function WorkplaceDeleteController($uibModalInstance, entity, Workplace) {
        var vm = this;

        vm.workplace = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.iconState = iconState;
        vm.deleting = false;



        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            vm.deleting = true;
            Workplace.delete(
                { id: id },
                function () {
                    $uibModalInstance.close(true);
                },
                function () {
                    vm.deleting = false;
                }
            );
        }

        function iconState() {
            return  {
                'fa-trash-alt': !vm.workplace.totalOfActiveUsers && !vm.deleting,
                'fa-exclamation-triangle': vm.workplace.totalOfActiveUsers && !vm.deleting,
                'fa-spinner': vm.deleting,
                'fa-spin': vm.deleting,
            };
        }
    }
})();
