/**
 * @typedef IResBalanceStatus
 * @prop {boolean} done
 * @prop {string} errorDescription
 */

(function () {
    'use strict';

    angular
        .module('stpApp')
        .controller('RoutePlanningController', RoutePlanningController);

    RoutePlanningController.$inject = ['$scope', '$state', '$filter', 'Project', '$stateParams', 'projectResource', 'mapPropertyResource', 'Mousetrap',
        'MapProperty', 'Route', 'startPoinResource', 'ngToast', 'Depot', 'DailyRouteStatService', 'AdmarService', 'RoutePlanningDataShare',
        '$translate', 'appEnv', 'RoutePlanningConstants', 'depotStatsSummary', 'RoutePlanningModalFactory', 'RoutePlanningLeafletObjectFactory', 'RoutePlanningService',
        'baseMapTileLayers', 'admTileLayers', 'Stop', '$timeout', 'isOneTimeUser', 'ColorPaletteConstant', 'OutletAssignmentService', 'oneClickFeatureToggle', '$q',
        'HTTP_CODE_CONCURRENT_USER_ID_MISMATCH', '$rootScope'];

    function RoutePlanningController($scope, $state, $filter, Project, $stateParams, projectResource, mapPropertyResource, Mousetrap,
        MapProperty, Route, startPoinResource, ngToast, Depot, DailyRouteStatService, AdmarService, RoutePlanningDataShare,
        $translate, appEnv, RoutePlanningConstants, depotStatsSummary, RoutePlanningModalFactory, RoutePlanningLeafletObjectFactory, RoutePlanningService,
        baseMapTileLayers, admTileLayers, Stop, $timeout, isOneTimeUser, ColorPaletteConstant, OutletAssignmentService, oneClickFeatureToggle, $q,
        HTTP_CODE_CONCURRENT_USER_ID_MISMATCH, $rootScope) {
        var vm = this;
        var CONST = RoutePlanningConstants;

        vm.service = RoutePlanningService;
        vm.sharedData = RoutePlanningDataShare;
        vm.isOneTimeUser = isOneTimeUser;

        vm.getFilters = getFilters;
        vm.getDataStyles = getDataStyles;
        vm.getDepots = getDepots;
        vm.getSelectedRoute = getSelectedRoute;

        vm.save = save;
        vm.onChangeView = onChangeView;
        vm.onColorChange = onColorChange;
        vm.onFilter = onFilter;
        vm.updateRoutes = updateRoutes;
        vm.addTerritory = addTerritory;
        vm.findGroupWeek = findGroupWeek;
        vm.fillWeekConfig = fillWeekConfig;
        vm.assignWeeks = assignWeeks;
        vm.findGroupDay = findGroupDay;
        vm.fillDayConfig = fillDayConfig;
        vm.assignDays = assignDays;
        vm.selectOnCheck = selectOnCheck;
        vm.project = projectResource;
        vm.routeDataProvider = routeDataProvider;
        vm.depotDataProvider = depotDataProvider;
        vm.setShortestTrip = setShortestTrip;
        vm.centerToDepot = centerToDepot;
        vm.checkAllRoute = checkAllRoute;
        vm.routeChecked = routeChecked;
        vm.openRouteDetailsDialog = openRouteDetailsDialog;
        vm.oneClickOptimization = oneClickOptimization;

        vm.onModeChange = onModeChange;
        vm.getQueryString = vm.service.getQueryString;
        vm.confirmBalancing = confirmBalancing;
        vm.onAutoBalanceSuccess = onAutoBalanceSuccess;
        vm.onAutoBalanceError = onAutoBalanceError;
        vm.updateScope = updateScope;
        vm.openChangeFreqDialog = openChangeFreqDialog;
        vm.fetchDepotStatsSummary = fetchDepotStatsSummary;
        vm.setDepotStatsSummary = setDepotStatsSummary;
        vm.featureEnabled = featureEnabled;
        vm.startDrawing = startDrawing;
        vm.deselectStop = deselectStop;
        vm.drawDepotGravity = drawDepotGravity;
        vm.onSavedSettingPoi = onSavedSettingPoi;
        vm.addLimit = addLimit;
        vm.updateProp = updateProp;
        vm.filterFn = filterFn;
        vm.autoBalanceDialog = autoBalanceDialog;

        vm.sharedData.filters = { territory: '', week: '', day: '', depot: '' };
        vm.selectionInfoControl = { territory: null, week: null, day: null, depot: null };
        vm.limit = 20;

        vm.onSelectedMode = 'title';
        vm.onChangeModes = onChangeModes;
        vm.broadcastOnChangeMode = $scope.$on('update-stop', broadcastOnChangeMode);

        vm.sharedData.currentView = CONST.TERRITORY;
        var oldMapProp;
        var firstLoad = true;
        var osrmLimit = appEnv['osrmStopSizeLimit'];
        var sanitizedOsrmLimit = osrmLimit ? osrmLimit : 500;

        vm.stopLimit = sanitizedOsrmLimit - 2;
        vm.sharedData.depots = startPoinResource;
        vm.sharedData.disableTab = true;
        vm.sharedData.weekTab = 1;
        vm.sharedData.weekConfig = {};
        vm.sharedData.weekConfigIsValid = {};
        vm.sharedData.dayTab = 1;
        vm.sharedData.dayConfig = {};
        vm.sharedData.dayConfigIsValid = {};
        vm.sharedData.numOfValues = vm.project.props.numOfValues;
        vm.totalStop = 0;
        vm.totalValue = 0;
        vm.totalValue2 = 0;
        vm.totalValue3 = 0;
        vm.totalStopStatistic = 0;
        vm.totalVisit = 0;
        vm.unassignStopCount = 0;
        vm.unassignStops = [];

        vm.invalidTime = false;
        vm.invalidDuration = false;
        vm.showRoute = showRoute;
        vm.hideOutletList = hideOutletList;
        vm.hideDepotList = hideDepotList;
        vm.setTime = setTime;
        vm.onTimeChanged = onTimeChanged;
        vm.onTimeChangedOutlet = onTimeChangedOutlet;
        vm.clearAlert = clearAlert;
        vm.dataFound = true;
        vm.radio = { territory: null, week: null, day: null };
        vm.milisMultiple = 60000;
        vm.modeOptions = CONST.MODEOPTIONS;
        vm.mode = CONST.POINT;
        vm.routingViewBy =  {
            territory: null, week: null, day: null, token: null
        };
        vm.timeWindowCollection = [];

        vm.masterToggleLayer = true;
        vm.toggleLayer = {};
        vm.onMasterToggleLayerChange = onMasterToggleLayerChange;
        vm.onToggleLayerChange = onToggleLayerChange;
        vm.cancelMoveStop = cancelMoveStop;
        vm.saveNewStopLatLng = saveNewStopLatLng;
        vm.onLatLngChange = onLatLngChange;
        vm.onTerritoryNameChanged = onTerritoryNameChanged;
        vm.onTerritoryNameClick = onTerritoryNameClick;
        vm.onTerritoryNameBlur = onTerritoryNameBlur;
        vm.onTerritoryDelete = onTerritoryDelete;
        vm.loadRouteCheckBoxStatus = loadRouteCheckBoxStatus;
        vm.saveNewDepotLatLng = saveNewDepotLatLng;
        vm.onSyncSuccess = onSyncSuccess;
        vm.clearSelectedFilter = clearSelectedFilter;
        vm.showHideAdministrativeArea = showHideAdministrativeArea;

        vm.onDurationChange = onDurationChange;

        vm.areaByPoi = {
            depot: {},
            territory: {},
            week: {},
            day: {}
        };
        vm.areaToggle = {};
        vm.areaLoading = {};

        vm.depotsStats = {};

        /* lock/unlock outlet in outlet table */
        vm.allOutletLock = {
            depot: false,
            territory: false,
            week: false,
            day: false,
        };
        vm.checkAllOutletLock = checkAllOutletLock;
        vm.checkOutletLock = checkOutletLock;
        vm.saveEachLock = saveEachLock;
        vm.saveAllLock = saveAllLock;

        vm.searchQuery = {
            properties: {
                stopExternalId: '',
                stopName: '',
                stopProps: {
                    value: '',
                    value2: '',
                    value3: '',
                    notes: [],
                    timeWindowStr: 'all'
                },
                inStopDuration: '',
                stopVisitFreq: [],
            }
        };

        vm.collectionFrequency = [];
        vm.addNotesFilter = addNotesFilter;
        vm.removeNotesFilter = removeNotesFilter;
        vm.querySingleNotes = '';
        vm.resetLimitByFilter = resetLimitByFilter;
        vm.autoBalanceByDepot = autoBalanceByDepot;
        vm.autoBalanceByTerritory = autoBalanceByTerritory;
        vm.autoBalanceByWeek = autoBalanceByWeek;
        vm.autoBalanceByDay = autoBalanceByDay;
        vm.colors = ColorPaletteConstant;
        vm.oneClickFeatureToggle = oneClickFeatureToggle;
        vm.overTimeRoute = [];
        vm.projectErrorSummary = {
            overtime: [],
            overStop: [],
            overCapacity: [],
            unassignStops: [],
            unassignStopCount: 0,
            overtimeCount: 0,
            overStopCount: 0,
            overCapacityCount: 0,
        };
        vm.isLoadingProjectErrorSummary = false;
        vm.hasRouteViolation = false;
        vm.loadProjectErrorSummary = loadProjectErrorSummary;
        vm.reloadRoutePlanCore = reloadRoutePlanCore;
        vm.openBulkDeleteTerritoryDialog = openBulkDeleteTerritoryDialog;

        setDepotStatsSummary(depotStatsSummary);

        vm.service.featureSetting();

        /* route layer(point) */
        vm.sharedData.routeLayer = RoutePlanningLeafletObjectFactory.getRouteLayer(findGroupDay, findGroupWeek, updateScope, openChangeFreqDialog, moveStop, openOutletLockDialog, addTerritory, openDeleteRouteStopDialog, openUnassignDialog);
        /* administration layer */
        vm.sharedData.admLayer = RoutePlanningLeafletObjectFactory.getAdmLayer(updateScope, findGroupDay, findGroupWeek);
        /* unassign layer */
        vm.sharedData.unassignLayer = RoutePlanningLeafletObjectFactory.getUnassignStopLayer(assignToRoute);


        /* get layer BATAS WILAYAH */
        var adm = admTileLayers;

        /* base map layer */
        var baseMaps = baseMapTileLayers;

        /* init main map */
        vm.service.initMap(baseMaps);
        /* map interaction */
        vm.service.mapInteractions(updateScope);

        /* control sidebar */
        var sidebar = L.control.sidebar('sidebar').addTo(vm.sharedData.map);

        vm.service.setFilters($state, $stateParams);

        loadTimeSetting();
        vm.collectionTimeWindow = [];

        if (mapPropertyResource.length) {
            oldMapProp = angular.copy(mapPropertyResource[0].style);
            vm.mapProp = mapPropertyResource[0];
            vm.sharedData.dataStyle = mapPropertyResource[0].style;
            if(vm.isOneTimeUser) {
                generateDailyRouteStat(vm.project.id);
            } else {
                loadGeoPoin();
                loadProjectErrorSummary(true);
                /* add depot */
                drawDepot(vm.sharedData.depots);
            }
        } else {
            vm.mapProp = null;
            vm.sharedData.dataStyle = null;
            sidebar.open("settings");
        }

        _intervalPolling();

        /* custom button control */
        if(!vm.isOneTimeUser) {
            var importButton = L.easyButton('<i class="glyphicon glyphicon-transfer"></i>', function (btn, map) {
                if(vm.disableOnLoad) {
                    return;
                }
                openImportDialog();
            }, 'Import & Export', { position: 'topright' }).addTo(vm.sharedData.map);
        }

        // add button to view outlet table
        $('#outlet-legend').hide();
        var listOutletButton = L.easyButton('<i id="btn-outlet-list" class="glyphicon glyphicon-list-alt"></i>', function (btn, map) {
            showOutletList();
        }, 'Show Data', { position: 'topright' }).addTo(vm.sharedData.map);

        // add button to view depot table
        $('#depot-legend').hide();
        var listDepotButton = L.easyButton('<i id="btn-depot-list" class="fa fa-home">', function (btn, map) {
            showDepotList();
        }, 'Depot', { position: 'topright' }).addTo(vm.sharedData.map);

        /* measure control */
        var measureControl = new L.Control.Measure({
            position: 'topright',
            primaryLengthUnit: 'kilometers',
            secondaryLengthUnit: 'meters',
            primaryAreaUnit: 'hectares',
            secondaryAreaUnit: 'sqmeters',
            activeColor: '#011936',
            completedColor: '#F55D3E'
        }).addTo(vm.sharedData.map);

        // depot gravity button
        addDepotGravityButton();

        /* key binding */
        Mousetrap.bind(['shift+1', 'command+1'], function () {
            if (!vm.sharedData.filters.depot) { return; };
            sidebar.open(CONST.TERRITORY);
            onChangeView(CONST.TERRITORY);
        });
        Mousetrap.bind(['shift+2', 'command+2'], function () {
            if (!vm.sharedData.filters.depot) { return; };
            sidebar.open(CONST.WEEKS);
            onChangeView(CONST.WEEK);
        });
        Mousetrap.bind(['shift+3', 'command+3'], function () {
            if (!vm.sharedData.filters.depot) { return; }
            sidebar.open(CONST.DAYS);
            onChangeView(CONST.DAY);
        });
        Mousetrap.bind(['shift+d', 'shift+D'], function () {
            vm.sharedData.polygon.disable();
            vm.sharedData.circle.disable();
            vm.sharedData.rectangle.disable();
            vm.sharedData.isDrawingMode = false;
            deselectStop(true);
            vm.service.deselectUnassignStop();
            updateScope();
        });

        var translateEvent$ = $rootScope.$on('$translateChangeSuccess', function(event, current, previous) {
            vm.selectionInfoControl = vm.service.loadSelectionInfoControl(vm.sharedData.filters);
        });

        $scope.$on('$destroy', function() {
            // Lakukan sesuatu sebelum controller dihancurkan
            if(angular.isDefined(translateEvent$) && translateEvent$ !== null){
                translateEvent$();
            }
        });

        function resetFilterLegend() {
            vm.searchQuery = {
                properties: {
                    stopExternalId: '',
                    stopName: '',
                    stopProps: {
                        value: '',
                        value2: '',
                        value3: '',
                        notes: [],
                        timeWindowStr: 'all'
                    },
                    inStopDuration: '',
                    stopVisitFreq: [],
                }
            };
        }

        function getFilters() {
            return vm.sharedData.filters;
        }

        function getDataStyles() {
            return vm.sharedData.dataStyle;
        }

        function getDepots(){
            return vm.sharedData.depots;
        }

        function getSelectedRoute(){
            return vm.sharedData.selectedRoute;
        }

        function onChangeView (view) {
            if (vm.sharedData.currentView != view) {
                vm.onSelectedMode = 'title';
                vm.sharedData.currentView = view;
                if (vm.sharedData.currentView === CONST.DEPOT) {
                    Object.keys(vm.sharedData.filters).forEach(function (key) {
                        vm.sharedData.filters[key] = key !== CONST.DEPOT ? null : vm.sharedData.depots.length === 1 ? vm.sharedData.filters[CONST.DEPOT] : null;
                    });
                } else {
                    vm.sharedData.filters[view] = null;
                }
                vm.toggleLayer = {};
                deselectStop(true);
                loadGeoPoin();
                loadProjectErrorSummary();
                drawDepot(vm.sharedData.depots);
                transition();
                addDepotGravityButton();
                vm.sharedData.totalList = {
                    depot: vm.getDepots().length || 0,
                    territory : vm.getFilters().depot ? Object.keys(vm.getDataStyles().depots[vm.getFilters().depot].territories).length : 0,
                    week: vm.getFilters().depot ? Object.keys(vm.getDataStyles().depots[vm.getFilters().depot].weeks).length : 0,
                    day: Object.keys(vm.getDataStyles().days).length || 0,
                };
                $scope.$broadcast('change-view');
            }
        }

        /* restyle routeLayer */
        function redrawPoin () {
            vm.service.redrawRouteLayer(openChangeFreqDialog, moveStop);
        }

        function drawDepot (depots) {
            if (!depots.length) {
                return;
            }
            vm.depots = depots;
            if (vm.sharedData.depotLayer) {
                vm.sharedData.depotLayer.clearLayers();
            }
            if (vm.sharedData.filters.depot && vm.sharedData.currentView !== CONST.DEPOT) {
                vm.depots = depots.filter(function (x) {
                    return x.id == vm.sharedData.filters.depot;
                });
            }
            if (vm.radio.depot && vm.sharedData.currentView === CONST.DEPOT) {
                vm.depots = depots.filter(function (x) {
                    return x.id == vm.radio.depot;
                });
            }
            vm.depots.forEach(function (elm) {
                var depotColor = vm.sharedData.dataStyle.depots[elm.id] ? vm.sharedData.dataStyle.depots[elm.id].properties.color : '#EE7B30';
                var depotMarker;
                if(isOneTimeUser) {
                    depotMarker = RoutePlanningLeafletObjectFactory.depotIcon(elm, depotColor).addTo(vm.sharedData.depotLayer);
                } else {
                    depotMarker = RoutePlanningLeafletObjectFactory.depotIcon(elm, depotColor, moveStop).addTo(vm.sharedData.depotLayer);
                }
                depotMarker.on('mouseover', function(){
                    standOutDepotGravityByDepotId(elm.id);
                });
                depotMarker.on('mouseout', function(){
                    resetDepotGravityStyle();
                });
            });
        }

        function standOutDepotGravityByDepotId(depotId) {
            vm.sharedData.depotGravityLayer.eachLayer(function(groupLayers){
                groupLayers.eachLayer(function(layer){
                    var layerDepotId = layer.feature.properties.depotId;
                    if (layerDepotId !== depotId) {
                        layer.setStyle({ fillOpacity: 0.3, opacity: 0.2}).bringToBack();
                    } else {
                        layer.setStyle({ fillOpacity: 1, opacity: 1}).bringToFront();
                    }
                });
            });
        }

        function resetDepotGravityStyle() {
            vm.sharedData.depotGravityLayer.eachLayer(function(groupLayers){
                groupLayers.eachLayer(function(layer){
                    groupLayers.resetStyle(layer);
                    layer.bringToBack();
                });
            });
        }

        function loadStatic () {
            Route.getSummary({
                "projectId.equals": vm.project.id,
                "territory.in": vm.sharedData.filters.territory,
                "week.in": vm.sharedData.filters.week,
                "day.in": vm.sharedData.filters.day,
                "view": vm.sharedData.currentView.toUpperCase(),
                "depotId.equals": vm.sharedData.depots.length > 1 ? vm.sharedData.filters.depot : null
            }, function (summaries) {
                vm.totalValue = 0;
                vm.totalValue2 = 0;
                vm.totalValue3 = 0;
                vm.totalVisit = 0;
                vm.totalStopStatistic = 0;
                /* validator value */
                var filtersInArray = vm.service.transformFiltersToArrayData(vm.sharedData.filters);

                var isSingleRouteInTerritoryView = vm.sharedData.currentView == CONST.TERRITORY && filtersInArray.day.length == 1 && filtersInArray.week.length == 1;
                var isSingleRouteInWeekView = vm.sharedData.currentView == CONST.WEEK && filtersInArray.day.length == 1 && filtersInArray.territory.length == 1;
                var isSingleRouteInDayView = vm.sharedData.currentView == CONST.DAY && filtersInArray.territory.length == 1 && filtersInArray.week.length == 1;

                summaries.forEach(function (summary) {
                    vm.totalValue += summary.value;
                    vm.totalValue2 += summary.value2;
                    vm.totalValue3 += summary.value3;
                    vm.totalVisit += summary.visit;
                    vm.totalStopStatistic += summary.stop;

                    if (isSingleRouteInTerritoryView || isSingleRouteInWeekView || isSingleRouteInDayView) {
                        summary.invalidStop = summary.stop > vm.stopLimit;
                    }
                });

                if (isSingleRouteInTerritoryView || isSingleRouteInWeekView || isSingleRouteInDayView) {
                    generateListOfInvalidStop(summaries);
                }

                vm.totalValue = round(vm.totalValue);
                vm.statis = summaries.reduce(function (statis, data) {
                    if (!statis[data.arc]) {
                        statis[data.arc] = {};
                    }
                    statis[data.arc]['value'] = round(data.value);
                    statis[data.arc]['value2'] = round(data.value2);
                    statis[data.arc]['value3'] = round(data.value3);
                    statis[data.arc]['stop'] = data.stop;
                    statis[data.arc]['visit'] = data.visit;
                    statis[data.arc]['invalidStop'] = data.invalidStop;
                    return statis;
                }, {});

                vm.isLoadingStatisticData = false;
            }, function(){
                vm.isLoadingStatisticData = false;
            });
        }

        /**
         * @param {boolean | undefined} isOnlyLoadGeoJson just load GeoJson (optional)
         */
        function loadGeoPoin (isOnlyLoadGeoJson) {
            var onlyGeoJson = isOnlyLoadGeoJson == undefined ? false : true;

            vm.limit = 20;
            vm.toggle = {};
            vm.sharedData.dayConfig = {};
            vm.sharedData.weekConfig = {};
            vm.listOfInvalidStop = [];
            vm.sharedData.filters[vm.sharedData.currentView] = vm.sharedData.currentView === CONST.DEPOT ? vm.sharedData.filters[vm.sharedData.currentView] : null;
            vm.sharedData.selectedRoute = new Set();
            vm.sharedData.selectedUnassignStop = new Set();
            vm.sharedData.disableTab = (vm.sharedData.selectedRoute.size == 0);
            vm.disableOnLoad = true;
            vm.selectionInfoControl = vm.service.loadSelectionInfoControl(vm.sharedData.filters);
            vm.sharedData.areaByPoiLayer.clearLayers();
            resetLayerButton(vm.sharedData.currentView);

            if (!onlyGeoJson) {
                vm.statis = {};
                vm.isLoadingStatisticData = true;
                loadStatic();
            }

            var loadToast = ngToast.info(vm.sharedData.toastInfo);
            var toggleLayerKeys =  Object.keys(vm.toggleLayer).filter(function (k) {
                return vm.toggleLayer[k];
            }).join(',');

            Route.getGeoJson({
                "projectId.equals": vm.project.id,
                "territory.in": vm.sharedData.currentView === CONST.TERRITORY && toggleLayerKeys.length ? toggleLayerKeys : vm.sharedData.filters.territory,
                "week.in": vm.sharedData.currentView === CONST.WEEK && toggleLayerKeys.length ? toggleLayerKeys : vm.sharedData.filters.week,
                "day.in": vm.sharedData.currentView === CONST.DAY && toggleLayerKeys.length ? toggleLayerKeys : vm.sharedData.filters.day,
                "depotId.equals": vm.sharedData.filters.depot,
                "uniqueStop": "true"
            }, onSuccess, onError);
            function onSuccess (data) {
                ngToast.dismiss(loadToast);
                dynamicLabel(data, 'stopName');
                vm.sharedData.routeLayer.clearLayers();
                vm.sharedData.stops = data;
                vm.routeGeom = convertStopDurationMsIntoMinutes(data);

                vm.collectionFrequency = vm.routeGeom.features.filter(function(item, index, self) {
                    return index === self.findIndex(function(t) {
                        return t.properties.stopVisitFreq === item.properties.stopVisitFreq;
                    });
                }).map(function(item) {
                    return item.properties.stopVisitFreq;
                });
                vm.routeGeom.features.forEach(function (item) {
                    item.properties.stopProps.timeWindowStr = printTimeWindow(item.properties);
                });

                var timeWindowCollection = vm.routeGeom.features.map(function (item) {
                    return item.properties.stopProps.timeWindowStr;
                }).filter(function (value, index, array) {
                    return array.indexOf(value) === index;
                }).filter(function(x){return x;}).map(function(timeString) {
                    return { value: timeString, label: timeString };
                });
                vm.timeWindowCollection = timeWindowCollection;

                vm.sharedData.routeLayer.addData(vm.routeGeom);
                vm.totalStop = data.features.length;
                if (firstLoad) {
                    sidebar.close();
                    vm.sharedData.map.fitBounds(vm.sharedData.depotLayer.getBounds().extend(vm.sharedData.routeLayer.getBounds()));
                    firstLoad = false;
                    mixpanel.track('Project Opened', {
                        'outletCount': vm.totalStop
                    });
                }
                if (vm.sharedData.depots.length && mapPropertyResource.length) {
                    sidebar.open(vm.sharedData.currentView);
                }
                redrawPoin();
                vm.disableOnLoad = false;
                // if (!onlyGeoJson) {
                //     loadStatic();
                // }
                createConvexHull(vm.sharedData.stops);
            }
            function onError (error) {
                vm.sharedData.routeLayer.clearLayers();
                sidebar.close();
                vm.totalStop = 0;
                vm.routeGeom = null;
                ngToast.dismiss(loadToast);
                vm.disableOnLoad = false;
                createErrorToast(error);
            }
        }

        function transition () {
            $state.transitionTo($state.$current, {
                id: vm.project.id,
                view: vm.sharedData.currentView,
                territory: vm.sharedData.filters.territory,
                week: vm.sharedData.filters.week,
                day: vm.sharedData.filters.day,
                depot: vm.sharedData.filters.depot
            }, { notify: false });
        }

        /**
         *
         * @param {string} view ['territory', 'depot', 'week', 'day']
         * @param {number} key number position territory
         * @param {boolean | undefined} isMustEmptyStopIds set to Empty Stop (optional)
         */
        function updateRoutes (view, key, isMustEmptyStopIds) {
            vm.disableOnLoad = true;
            switch (view) {
                case CONST.TERRITORY:
                    vm.service.updateTerritoryRoute(key, vm.project, isMustEmptyStopIds).then(function (respond) {
                        mixpanel.track('Stop Assignment Successful', {
                            'section': 'Territory',
                            'stopCount': vm.totalStop
                        });
                        $scope.$broadcast('update-stop');
                        reloadRoutePlanCore();
                    }, function (error) {
                        vm.disableOnLoad = false;
                        createErrorToast(error);
                    });
                    break;
                case CONST.WEEK:
                    vm.service.updateWeekRoute(key).then(function (respond) {
                        mixpanel.track('Stop Assignment Successful', {
                            'section': 'Week',
                            'stopCount': vm.totalStop
                        });
                        $scope.$broadcast('update-stop');
                        reloadRoutePlanCore();
                    }, function (error) {
                        vm.disableOnLoad = false;
                        createErrorToast(error);
                    });
                    break;
                case CONST.DEPOT:
                    vm.service.updateDepotRoute(key, vm.project).then(function (respond) {
                        mixpanel.track('Stop Assignment Successful', {
                            'section': 'Depot',
                            'stopCount': vm.totalStop
                        });
                        $scope.$broadcast('update-stop');
                        reloadRoutePlanCore();
                    }, function (error) {
                        vm.disableOnLoad = false;
                        createErrorToast(error);
                    });
                    break;
            }
        }

        /* filter */
        function onFilter (view, key, status) {
            if (status) {
                vm.service.filterBuilder(view, key);
            } else {
                vm.service.filterRemover(view, key);
            }
        }

        /* add and remove Territory */
        function addTerritory (isMustEmptyStopIds) {
            vm.disableOnLoad = true;
            var styleList = Object.keys(vm.sharedData.dataStyle.depots[vm.sharedData.filters.depot].territories);
            var objSize = styleList.length ? parseInt(styleList[styleList.length - 1]) + 1 : 1;
            vm.sharedData.dataStyle[CONST.DEPOTS][vm.sharedData.filters.depot]['territories'][objSize] = {};
            vm.sharedData.dataStyle[CONST.DEPOTS][vm.sharedData.filters.depot]['territories'][objSize].color = vm.colors[objSize];
            var updatePp = updateProp();
            updatePp.$promise.then(function (success) {
                updateRoutes(CONST.TERRITORY, objSize, isMustEmptyStopIds);
            });
        }

        /* update project */
        function save () {
            vm.isSaving = true;
            var days = vm.project.props.nearestToDepotDayOrders;
            var maxDay = vm.project.props.maxDay;
            if (!days.length || days.length != maxDay) {
                days = [];
                for (var i = 0; i < maxDay; i++) {
                    days.push({
                        day: i + 1,
                        sequence: i
                    });
                }
            }
            vm.project.props.nearestToDepotDayOrders = days;
            Project.update(vm.project, onSaveSuccess, onSaveError);
            function onSaveSuccess (result) {
                mixpanel.track('Project Setting Changed', {
                    'settingType': {
                        'Avoid Tolls': result.props.avoidTolls,
                        'Auto Balance Day': result.props.nearestToDepotDayOrders,
                        'Working Hour': result.props.dayTimeWindows,
                        'POI': result.props.selectedPOIs,
                        'BPS': result.props.selectedDemographics
                    }
                });
                $scope.$emit('stpApp:projectUpdate', result);
                vm.isSaving = false;
                sidebar.close();
                ngToast.create('Project updated!');
                loadTimeSetting();
            }
            function onSaveError (error) {
                vm.isSaving = false;
                if (error.status != HTTP_CODE_CONCURRENT_USER_ID_MISMATCH) {
                    createErrorToast(error);
                }
            }
        }

        /* update map prop */
        function updateProp () {
            vm.mapProp.style = vm.sharedData.dataStyle;
            oldMapProp = angular.copy(vm.mapProp.style);
            return MapProperty.update(vm.mapProp);
        }

        function deselectStop (removeToggle) {
            if (removeToggle) {
                vm.toggle = {};
                vm.sharedData.filters[vm.sharedData.currentView] = null;
                transition();
            }
            vm.service.deselectStop();
            vm.service.deselectUnassignStop();
            vm.routeGeom.features.forEach(function(each){
                each.isChecked = false;
            });
            vm.allRouteChecked = false;
        }

        /* import dialog */
        function openImportDialog () {
            var importDialog = RoutePlanningModalFactory.importModal(vm.totalVisit, vm.sharedData.dataStyle, vm.project);

            importDialog.result.then(function (result) {

                if (result.type === 'STOP_DATA') {
                    openStopImportDialog(result.parseData);
                }

                if (result.type === 'ROUTE_DATA_BY_DEPOT') {
                    openRouteImportDialog(result.parseData, result.depot);
                }
            }, function (reloadRouteplan) {
                if (reloadRouteplan === true) {
                    firstLoad = true;
                    reloadRoutePlanCore();
                }
            });
        }

        function openChangeFreqDialog (feature) {
            var changeFreqDialog = RoutePlanningModalFactory.changeFreqModal(feature, vm.sharedData.selectedRoute, vm.project.id, vm.project.props.maxDay);
            changeFreqDialog.result.then(function () {
                vm.sharedData.weekTab = 1;
                reloadRoutePlanCore();
            });
        }

        function autoBalanceDialog() {
            var changeFreqDialog = RoutePlanningModalFactory.autoBalanceDialog();
            changeFreqDialog.result.then(_onSuccessAutoBalance);


            /**
             * on success auto balance
             * @param {String} type territory, week, day
             */
            function _onSuccessAutoBalance(type) {
                if (type === 'territory') {
                    generateDailyRouteStat(vm.project.id, vm.sharedData.filters.depot);
                    return;
                }
                reloadRoutePlanCore();
            }
        }

        function reloadRoutePlanCore () {
            var isReloadFinish = $q.defer();

            vm.radio.depot = null;
            MapProperty.query({
                "projectId.equals": vm.project.id,
            }).$promise.then(function (mapPropRes) {
                // reload style
                if (!mapPropRes.length) {
                    return;
                }
                vm.mapProp = mapPropRes[0];
                vm.sharedData.dataStyle = mapPropRes[0].style;
                oldMapProp = angular.copy(mapPropRes[0].style);
                // reload depot
                Depot.query({
                    projectId: vm.project.id,
                }).$promise.then(function (depotRes) {
                    vm.sharedData.depots = depotRes;
                    var isDepotExist = vm.sharedData.depots.find(function (x) { return x.id == vm.sharedData.filters.depot; });

                    if (vm.sharedData.depots.length && isDepotExist) {
                        vm.sharedData.filters.depot = vm.sharedData.filters.depot && vm.sharedData.currentView === CONST.DEPOT ? null : vm.sharedData.filters.depot;
                        vm.sharedData.currentView = vm.sharedData.filters.depot ? vm.sharedData.currentView : CONST.DEPOT;
                    } else if (vm.sharedData.depots.length && !isDepotExist) {
                        vm.sharedData.filters.depot = vm.sharedData.depots.length === 1 ? vm.sharedData.depots[0].id : null;
                        vm.sharedData.currentView = vm.sharedData.filters.depot !== null ? CONST.TERRITORY : CONST.DEPOT;
                    }
                    addDepotGravityButton();
                    drawDepot(vm.sharedData.depots);
                    loadGeoPoin();
                    loadProjectErrorSummary(true);
                    isReloadFinish.resolve();
                });

                fetchDepotStatsSummary();

            });

            Project.get({id:vm.project.id}, function(data){
                vm.project = data;
                vm.sharedData.numOfValues = vm.project.props.numOfValues;
                loadTimeSetting();
            });
            return isReloadFinish.promise;
        }

        function setDepotStatsSummary (stats) {
            stats.forEach(function (stat) {
                vm.depotsStats[stat.depotId] = vm.service.formatToDepotStat(stat);
            });
        }

        function fetchDepotStatsSummary () {
            vm.depotsStats = {};
            Depot.getDepotStatsSummary({
                "projectId": $stateParams.id
            }).$promise.then(function (depotStatRes) {
                setDepotStatsSummary(depotStatRes);
            });
        }

        function onColorChange (obj) {
            var isMapPropNotChange = angular.equals(vm.sharedData.dataStyle, oldMapProp);
            if (isMapPropNotChange) {
                return;
            } else {
                drawDepot(vm.sharedData.depots);
                createConvexHull(vm.sharedData.stops);
                redrawPoin();
                updateProp();
            }
        }

        function findGroupDay () {
            vm.service.findGroupDay();
        }

        function fillDayConfig (freq, day, status) {
            vm.service.fillDayConfig(freq, day, status);
            vm.isDayConfigValid = vm.service.isDaysValid();
        }

        function assignDays () {
            vm.toggle = {};
            vm.disableOnLoad = true;
            var loadToast = ngToast.info(vm.sharedData.toastInfo);
            vm.service.assignDays()
                .then(function (respond) {
                    mixpanel.track('Stop Assignment Successful',{
                        'section': 'Day',
                        'stopCount': vm.totalStop
                    });
                    $scope.$broadcast('update-stop');
                    vm.sharedData.dayTab = 1;
                    ngToast.dismiss(loadToast);
                    loadGeoPoin();
                }, function (error) {
                    vm.sharedData.dayTab = 1;
                    vm.toggle = {};
                    vm.sharedData.dayStatus = {};
                    vm.sharedData.dayConfigIsValid = {};
                    vm.sharedData.groupDay = {};
                    if (error.status != HTTP_CODE_CONCURRENT_USER_ID_MISMATCH) {
                        createErrorToast(error);
                    }
                });
        }

        function findGroupWeek () {
            vm.service.findGroupWeek();
        }

        function fillWeekConfig (freq, key, week, status) {
            vm.service.fillWeekConfig(freq, key, week, status);
            vm.isWeekConfigValid = vm.service.isWeeksValid();
        }

        function assignWeeks () {
            vm.disableOnLoad = true;
            vm.toggle = {};
            var loadToast = ngToast.info(vm.sharedData.toastInfo);
            vm.service.assignWeeks()
                .then(function (respond) {
                    mixpanel.track('Stop Assignment Successful',{
                        'section': 'Week',
                        'stopCount': vm.totalStop
                    });
                    $scope.$broadcast('update-stop');
                    vm.sharedData.weekTab = 1;
                    ngToast.dismiss(loadToast);
                    loadGeoPoin();
                    deselectStop(true);
                    vm.isWeekConfigValid = vm.service.isWeeksValid();
                    vm.sharedData.groupWeek = {};
                }, function (error) {
                    vm.sharedData.weekTab = 1;
                    vm.toggle = {};
                    vm.sharedData.weekStatus = {};
                    vm.sharedData.weekConfigIsValid = {};
                    vm.sharedData.groupWeek = {};
                    if (error.status != HTTP_CODE_CONCURRENT_USER_ID_MISMATCH) {
                        createErrorToast(error);
                    }
                });
        }

        function selectOnCheck () {
            deselectStop(false);
            vm.service.selectOnCheck(vm.toggle);
        }

        function showRoute (route) {
            var coord = [route.geometry.coordinates[1], route.geometry.coordinates[0]];
            vm.sharedData.map.setView(coord, 19);
        }

        function showOutletList () {
            $('#outlet-legend').show(100);
            $('#btn-outlet-list').hide();
        }

        function hideOutletList () {
            vm.limit = 20;
            resetFilterLegend();
            $('#outlet-legend').hide(100);
            $('#btn-outlet-list').show();
        }

        function showDepotList () {
            $('#depot-legend').show(100);
            $('#btn-depot-list').hide();
        }

        function hideDepotList () {
            vm.limit = 20;
            $('#depot-legend').hide(100);
            $('#btn-depot-list').show();
        }

        function loadTimeSetting () {
            vm.project.props.dayTimeWindows = vm.project.props.dayTimeWindows ? vm.project.props.dayTimeWindows : [];
            vm.timeSetting = vm.service.loadTimeSetting(vm.project);
        }

        function onTimeChanged (start, end) {
            vm.invalidTime = vm.service.onTimeChanged(vm.timeSetting, start, end);
        }

        function convertToEpoch (time, arr) {
            var momentDateStr = '1970-01-01', // unix gmt 0
                str = JSON.stringify(time),
                spl = str.slice(1, -1),
                mmt = moment(spl),
                gmtz = moment(mmt, 'hmmss').format('HH:mm:ss');

            var toSeventiesStr = momentDateStr + 'T' + gmtz + '+00:00',
                toUtc = moment(toSeventiesStr).utc(),
                epochUtc = moment(toUtc).valueOf() / 1000;
            arr.push(epochUtc);
            return arr;
        }

        function setTime (time) {
            var arrStartEpoch = [],
                arrEndEpoch = [],
                dayTimeWindows = [],
                totalDay = vm.project.props.maxDay;

            time.forEach(function (each) {
                each.startTime.setSeconds(0);
                each.endTime.setSeconds(0);
                arrStartEpoch = convertToEpoch(each.startTime, arrStartEpoch);
                arrEndEpoch = convertToEpoch(each.endTime, arrEndEpoch);
            });

            for (var i = 0; i < totalDay; i++) {
                dayTimeWindows.push({
                    "day": i + 1,
                    "endTime": arrEndEpoch[i],
                    "startTime": arrStartEpoch[i]
                });
            }
            vm.project.props.dayTimeWindows = dayTimeWindows;
            Project.updateTime(vm.project, onSuccess, onError);

            function onSuccess () {
                loadProjectErrorSummary(true);
                vm.updateSuccess = true;
                $("#updateOK").fadeTo(2000, 500).fadeOut(500, function () {
                    $("#updateOK").fadeOut(500);
                });
            }

            function onError (err) {
                vm.updateError = true;
                vm.responseUpdateError = err.data;

                $("#updateErr").fadeTo(2000, 500).fadeOut(500, function () {
                    $("#updateErr").fadeOut(500);
                });
            }
        }

        function clearAlert () {
            $("#updateOK").hide();
            $("#updateErr").hide();
        }

        function dynamicLabel (geoData, selectedLabel) {
            if (geoData && selectedLabel) {
                vm.sharedData.textLayer.clearLayers();
                vm.sharedData.map.fitBounds(vm.sharedData.map.getBounds());
                var geoJSONLayerText = RoutePlanningLeafletObjectFactory.dynamicLabel(geoData, selectedLabel);
                geoJSONLayerText.addTo(vm.sharedData.textLayer);
            }
        }

        function addLimit (dataLength) {
            if (vm.limit < dataLength) {
                vm.limit += 20;
            }
        }

        function routeDataProvider () {
            if (!vm.routeGeom) return [];
            var dataRoutes = vm.service.getRouteData(vm.routeGeom, vm.searchQuery, vm.milisMultiple, function () { vm.limit = 20; });
            vm.allRouteChecked = dataRoutes.length ? dataRoutes.every(function (route) {
                return route.isChecked;
            }) : false;

            return dataRoutes;
        }

        function depotDataProvider () {
            if (!vm.sharedData.depots) return [];
            var depotItems = vm.sharedData.depots;
            depotItems = vm.service.getDepotData(depotItems, vm.depotSearchQuery, vm.sharedData.filters.depot);
            return depotItems;
        }

        function setShortestTrip () {
            vm.disableOnLoad = true;
            var confirmed = confirm("It will reset all visit sequence in this project. Would you like to proceed?");
            if (confirmed) {
                var loadToast = ngToast.info(vm.sharedData.toastInfo);
                mixpanel.time_event('Set Shortest Trip Successful');
                Route.resetRouteSeq({projectId: vm.project.id, depot: vm.getFilters().depot}, _onSuccess, _onError);

                function _onSuccess(res) {
                    mixpanel.track('Set Shortest Trip Successful', {
                        'isAllRoutes': true,
                        'stopCount': vm.totalStop
                    });
                    loadProjectErrorSummary(true);
                    ngToast.dismiss(loadToast);
                    vm.disableOnLoad = false;
                }

                function _onError(error) {
                    vm.disableOnLoad = false;
                    createErrorToast(error);
                    ngToast.dismiss(loadToast);
                }
            } else {
                vm.disableOnLoad = false;
            }
        }

        function createErrorToast (error) {
            if (error.status == 404 || error.status == HTTP_CODE_CONCURRENT_USER_ID_MISMATCH) {
                return;
            }
            ngToast.danger({
                content: '<strong>'
                    + error.status + ' : '
                    + error.data.detail + '</strong>',
                dismissButton: true,
                timeout: 5000
            });
        }

        function centerToDepot (depot) {
            vm.sharedData.map.setView([depot.lat, depot.lon], 19);
        }

        function checkAllRoute() {
            vm.filteredGeom = $filter('filter')(vm.routeGeom.features, vm.filterFn);
            vm.sharedData.routeLayer.eachLayer(function (layer) {
                var stopId = layer.feature.properties.stopId;
                var isOnFilteredGeom = vm.filteredGeom.find(function(x){return x.properties.stopId == stopId;});

                if (isOnFilteredGeom) {
                    layer.setStyle(
                        RoutePlanningLeafletObjectFactory.selectedLayerStyle(vm.allRouteChecked)
                    );
                }
            });
            vm.filteredGeom.forEach(function (each) {
                each.isChecked = vm.allRouteChecked;
                if (vm.allRouteChecked) {
                    vm.sharedData.selectedRoute.add(each);
                }
                if (!vm.allRouteChecked) {
                    vm.sharedData.selectedRoute.delete(each);
                }
            });
        }

        function routeChecked (route) {
            var routeStopId = route.properties.stopId;

            vm.sharedData.routeLayer.eachLayer(function (layer) {
                var stopId = layer.feature.properties.stopId;
                if (stopId == routeStopId && route.isChecked) {
                    layer.setStyle(RoutePlanningLeafletObjectFactory.selectedLayerStyle(true));
                    vm.sharedData.selectedRoute.add(route);
                }
                if (stopId == routeStopId && !route.isChecked) {
                    layer.setStyle(RoutePlanningLeafletObjectFactory.selectedLayerStyle(false));
                    vm.sharedData.selectedRoute.delete(route);
                }
            });

            vm.allRouteChecked = vm.filteredGeom.every(function (each) {
                return each.isChecked;
            });
        }

        function loadRouteCheckBoxStatus () {
            if(vm.filteredGeom.length) {
                vm.allRouteChecked = vm.filteredGeom.every(function (each) {
                    return each.isChecked;
                });
            }
        }

        function round (num) {
            num = num ? num.toFixed(num % 1 && 2) : 0;
            return num;
        }

        function oneClickOptimization(balancingStatus) {
            if (!vm.oneClickFeatureToggle) {
                reloadRoutePlanCore();
                return;
            }

            var oneClickOptimizationDialog = RoutePlanningModalFactory.oneClickOptimization(vm.project, vm.mapProp, balancingStatus);
            oneClickOptimizationDialog.result.then(_close);

            /**
             * @param {IResBalanceStatus} res
             */
            function _close(res) {
                reloadRoutePlanCore();
            }
        }

        function openRouteDetailsDialog (filterBy) {
            var routeDetailsDialog = RoutePlanningModalFactory.routeDetailModal(filterBy, vm.stopLimit, vm.projectErrorSummary.unassignStops);
            routeDetailsDialog.result.then(
                function (closeParams) {
                    $state.go('route-plan.routings', closeParams, { reload: true });
                },
                function (dismissParams) {
                    if (dismissParams) {
                        reloadRoutePlanCore();
                    }
                }
            );
        }

        function onModeChange () {
            loadAdmArea();
        }

        function loadAdmArea () {
            var admAreaList = [adm.kelurahan, adm.kecamatan, adm.kabupaten, adm.provinsi];
            var routeLayerBound = vm.sharedData.routeLayer.getBounds();
            var unassignBound = vm.sharedData.unassignLayer.getBounds();
            var stopBound = routeLayerBound.extend(unassignBound).toBBoxString().split(',');

            /* clear adm area selection layer and adm area overlay from map */
            admAreaList.forEach(function (eachAdm) {
                vm.sharedData.map.removeLayer(eachAdm);
            });
            vm.sharedData.admLayer.clearLayers();

            function onLoadAdmAreaSuccess (data) {
                if (!data) {
                    return;
                }
                /* add new adm area layer */
                if (vm.mode === "PROVINSI") {
                    vm.sharedData.map.addLayer(adm.provinsi);
                } else if (vm.mode === "KABUPATEN") {
                    vm.sharedData.map.addLayer(adm.kabupaten);
                } else if (vm.mode === "KECAMATAN") {
                    vm.sharedData.map.addLayer(adm.kecamatan);
                } else if (vm.mode === "KELURAHAN") {
                    vm.sharedData.map.addLayer(adm.kelurahan);
                }
                vm.sharedData.admLayer.addData([data]);
                vm.sharedData.admLayer.addTo(vm.sharedData.map);
            }

            function onLoadAdmAreaError (error) {
                createErrorToast(error);
            }
            /* get adm area geoJSON */
            if (vm.mode !== "POINT") {
                var admarParams = {
                    areaType: vm.mode,
                    boundingBox: stopBound
                };
                AdmarService.getAdmAreaByBBox(admarParams, onLoadAdmAreaSuccess, onLoadAdmAreaError);
            }
        }

        function clearSelectedFilter (type) {
            vm.sharedData.filters[type] = null;
            $scope.$broadcast('update-stop');
            loadGeoPoin();
            transition();
        }

        function generateListOfInvalidStop (summaries) {
            vm.listOfInvalidStop = summaries.filter(function (summary) {
                return summary.invalidStop;
            }).map(function (summary) {
                return summary.arc;
            }).join(',');
        }

        function onAutoBalanceSuccess () {
            reloadRoutePlanCore();
            vm.disableOnLoad = false;
        }

        function onAutoBalanceError (error) {
            vm.disableOnLoad = false;
            ngToast.dismiss();
            if (error.status != HTTP_CODE_CONCURRENT_USER_ID_MISMATCH) {
                ngToast.danger({
                    content: '<strong>'
                        + error.data.status + ' : '
                        + $translate.instant(error.data.message, { limit: error.data.limit }) + '</strong>',
                    dismissButton: true,
                    timeout: 5000
                });
            }
        }

        function confirmBalancing (balancingFn) {
            var confirmDialog = RoutePlanningModalFactory.confirmBalancingModal(vm.routeGeom.features, vm.sharedData.currentView);
            confirmDialog.result.then(balancingFn, onDissmis);
            function onDissmis (result) {
                vm.disableOnLoad = false;
            }
        }

        function updateScope () {
            vm.sharedData.selectedRoute = new Set(vm.sharedData.selectedRoute);
            $scope.$apply(function ($scope) {
                $scope.vm.sharedData.dataStyle;
                $scope.vm.sharedData.selectedRoute;
                $scope.vm.sharedData.disableTab;
                $scope.vm.sharedData.groupDay;
                $scope.vm.sharedData.selectedUnassignStop;
            });
        }

        function featureEnabled(featureName) {
            return vm.service.featureEnabled(featureName);
        }

        function resetLayerButton(view) {
            // vm.toggleLayer = {};
            vm.areaToggle = {};
            vm.areaLoading = {};
            vm.masterToggleLayer = true;
            if (vm.sharedData.currentView == CONST.TERRITORY) {
                var currentViewStyleList = vm.getDataStyles().depots[vm.getFilters().depot].territories;
                for(var key in currentViewStyleList) {
                    vm.toggleLayer[key] = vm.toggleLayer[key] === undefined ? true: vm.toggleLayer[key];
                }
            } else if (vm.sharedData.currentView == CONST.WEEK) {
                var currentViewStyleList = vm.getDataStyles().depots[vm.getFilters().depot].weeks;
                for(var key in currentViewStyleList) {
                    vm.toggleLayer[key] = vm.toggleLayer[key] === undefined ? true: vm.toggleLayer[key];
                }
            } else if (vm.sharedData.currentView == CONST.DAY) {
                var currentViewStyleList = vm.getDataStyles().days;
                for(var key in currentViewStyleList) {
                    vm.toggleLayer[key] = vm.toggleLayer[key] === undefined ? true: vm.toggleLayer[key];
                }
            } else if (vm.sharedData.currentView == CONST.DEPOT) {
                var currentViewStyleList = vm.getDataStyles().depots;
                for(var key in currentViewStyleList) {
                    vm.toggleLayer[key] = vm.toggleLayer[key] === undefined ? true: vm.toggleLayer[key];
                }
            }
        }

        function onMasterToggleLayerChange(toggle) {
            for(var key in vm.toggleLayer) {
                vm.toggleLayer[key] = vm.masterToggleLayer;
            }
            filterLayerByToggleLayer(toggle);
        }

        function onToggleLayerChange(toggle) {
            vm.masterToggleLayer = Object.keys(vm.toggleLayer).every(function(k){ return vm.toggleLayer[k]; });
            filterLayerByToggleLayer(toggle);
        }

        function filterLayerByToggleLayer(toggle) {
            for(var k in vm.toggleLayer) {
                if(!vm.toggleLayer[k]) {
                    if(toggle !== undefined && vm.sharedData.currentView !== 'depot') {
                        toggle[k] = vm.toggleLayer[k];
                    }
                    vm.sharedData.selectedRoute.forEach(function (x) {
                        var isKeyViewFound = false;
                        if ( vm.sharedData.currentView !== 'depot') {
                            isKeyViewFound = x.properties.routes.some(function(x){return x[vm.sharedData.currentView] == k;});
                        }
                        if(isKeyViewFound) {
                            vm.sharedData.selectedRoute.delete(x);
                        }
                    });
                }
            }
            var loadToast = ngToast.info(vm.sharedData.toastInfo);

            var filter = Object.keys(vm.toggleLayer).filter(function (k) {
                return vm.toggleLayer[k];
            }).join(',');

            var queryParams = {
                "projectId.equals": vm.project.id,
                "territory.in": vm.sharedData.currentView === CONST.TERRITORY ? filter : vm.sharedData.filters.territory,
                "week.in": vm.sharedData.currentView === CONST.WEEK ? filter : vm.sharedData.filters.week,
                "day.in": vm.sharedData.currentView === CONST.DAY ? filter : vm.sharedData.filters.day,
                "depotId.in": vm.sharedData.currentView === CONST.DEPOT ? filter : vm.sharedData.filters.depot,
                "uniqueStop": "true"
            };

            if(filter !== ''){
                vm.disableOnLoad = true;
                Route.getGeoJson(queryParams, onSuccess, onError);
            } else {
                vm.sharedData.selectedRoute = new Set();
                vm.sharedData.routeLayer.clearLayers();
                ngToast.dismiss(loadToast);
                vm.disableOnLoad = false;
            }

            function onSuccess (data) {
                vm.sharedData.routeLayer.clearLayers();
                vm.routeGeom = convertStopDurationMsIntoMinutes(data);
                vm.collectionFrequency = vm.routeGeom.features.filter(function(item, index, self) {
                    return index === self.findIndex(function(t) {
                        return t.properties.stopVisitFreq === item.properties.stopVisitFreq;
                    });
                }).map(function(item) {
                    return item.properties.stopVisitFreq;
                });
                vm.routeGeom.features.forEach(function (item) {
                    item.properties.stopProps.timeWindowStr = printTimeWindow(item.properties);
                });

                var timeWindowCollection = vm.routeGeom.features.map(function (item) {
                    return item.properties.stopProps.timeWindowStr;
                }).filter(function (value, index, array) {
                    return array.indexOf(value) === index;
                }).filter(function(x){return x;}).map(function(timeString) {
                    return { value: timeString, label: timeString };
                });
                vm.timeWindowCollection = timeWindowCollection;

                dynamicLabel(data, 'stopName');
                vm.sharedData.routeLayer.addData(vm.routeGeom);
                vm.totalStop = data.features.length;
                redrawPoin();
                vm.sharedData.routeLayer.eachLayer(function (layer, features) {
                    var hasSelected = Array.from(vm.sharedData.selectedRoute).find(function(x){
                        return x.id == layer.feature.id;
                    });
                    if(hasSelected){
                        layer.setStyle({
                            color: '#ffffff',
                            weight: 2
                        });
                    }
                });
                vm.disableOnLoad = false;
                ngToast.dismiss(loadToast);
            }
            function onError (error) {
                vm.sharedData.routeLayer.clearLayers();
                ngToast.dismiss(loadToast);
                vm.disableOnLoad = false;

                if (error.status != 404 || error.status != HTTP_CODE_CONCURRENT_USER_ID_MISMATCH) {
                    createErrorToast(error);
                }
            }
        }

        function moveStop(layer, depotMove) {
            vm.depotMove = depotMove;
            vm.sharedData.movingMode = true;
            vm.newLat = layer._latlng.lat;
            vm.newLong = layer._latlng.lng;
            vm.originalStop = layer;
            var customIcon = L.icon({
                iconUrl: 'content/images/marker-icon.png',
                iconRetinaUrl: 'content/images/marker-icon-2x.png',
                shadowUrl: 'content/images/marker-shadow.png',
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
                shadowSize: [41, 41],
            });

            var customIconDepot = L.icon({
                iconUrl: 'content/images/depot-move-marker.png',
                iconSize: [25, 41],
                iconAnchor: [12, 38],
                popupAnchor: [1, -34],
                tooltipAnchor: [16, -28],
                shadowSize: [41, 41],
            });
            vm.movingMarker = L.marker(layer._latlng, {draggable: true, icon: vm.depotMove ? customIconDepot : customIcon});
            vm.movingMarker.on('drag', onDrag);
            hideUtility(true);
            updateScope();
        }

        function onDrag(e) {
            vm.newLat = e.latlng.lat;
            vm.newLong = e.latlng.lng;
            updateScope();
        }

        function cancelMoveStop() {
            vm.sharedData.movingMode = false;
            vm.isSavingMarker = false;
            hideUtility(false);
        }

        function saveNewStopLatLng() {
            vm.isSavingMarker = true;
            var stopId = vm.originalStop.feature.id;
            Stop.get({id:stopId}, _onGetStopSuccess, cancelMoveStop);
            function _onGetStopSuccess(stop) {
                stop.lat = vm.newLat;
                stop.lon = vm.newLong;
                Stop.update(stop, _onUpdateStopSuccess, cancelMoveStop);
            }

            function _onUpdateStopSuccess() {
                vm.isSavingMarker = false;
                vm.originalStop.setLatLng([vm.newLat, vm.newLong]);

                // force update vm.routeGeom for depot gravity input
                var copyOfGeom = angular.copy(vm.routeGeom);
                copyOfGeom.features = copyOfGeom.features.map(function(feature){
                    if(feature.id == stopId) {
                        feature.geometry.coordinates[0] = vm.newLong;
                        feature.geometry.coordinates[1] = vm.newLat;
                    }
                    return feature;
                });
                vm.routeGeom = copyOfGeom;
                $scope.$broadcast('update-stop');

                loadProjectErrorSummary(true);
                cancelMoveStop();
            }
        }

        function hideUtility(flag) {
            if (flag) {
                // hide all leaflet control
                $('.leaflet-control-container').hide();
                $('#sidebar').hide();
                // disable contextmenu on route layer
                vm.sharedData.routeLayer.eachLayer(function (layer) {
                    layer.options.contextmenuItems.forEach(function (x) {
                        x.disabled = true;
                    });
                });
                // add moving marker to map
                vm.movingMarker.addTo(vm.sharedData.map);
                // remove original stop from map
                vm.originalStop.removeFrom(vm.sharedData.map);
            } else {
                // show all leaflet control
                $('.leaflet-control-container').show();
                $('#sidebar').show();
                // enable contextmenu on route layer
                vm.sharedData.routeLayer.eachLayer(function (layer) {
                    layer.options.contextmenuItems.forEach(function (x) {
                        x.disabled = false;
                    });
                });
                // remove moving marker from map
                vm.movingMarker.removeFrom(vm.sharedData.map);
                // add original stop to map
                vm.originalStop.addTo(vm.sharedData.map);
            }
        }

        function onLatLngChange() {
            if(vm.newLat && vm.newLong) {
                vm.movingMarker.setLatLng([vm.newLat, vm.newLong]);
            }
        }

        function onTerritoryNameChanged(event, key) {
            // when enter keypress
            if(event.keyCode === 13) {
                vm.editModeName[key] = false;
                updateProp();
            }

            // when escape keypress
            if(event.keyCode === 27) {
                vm.editModeName[key] = false;
                vm.sharedData.dataStyle = angular.copy(oldMapProp);
            }
        }

        function onTerritoryNameBlur(key) {
            vm.editModeName[key] = false;
            vm.sharedData.dataStyle = angular.copy(oldMapProp);
        }

        function onTerritoryDelete(key, value) {
            var numOfLockedTerritory = vm.routeGeom.features.filter(function (f) {
                return f.properties.routes[0].territory == key && f.properties.territoryLocked;
            });

            if (Object.keys(vm.sharedData.dataStyle.depots[vm.getFilters().depot].territories).length === 1) {
                var cannotDelete = RoutePlanningModalFactory.cannotDeleteTerritoryDialog();
                cannotDelete.result.then(function () { });
                return;
            }

            var deleteTerritory = RoutePlanningModalFactory.deleteTerritoryDialog(key, value.name, numOfLockedTerritory.length);
            deleteTerritory.result.then(function (transfered) {
                if (transfered) {
                    var stop = vm.statis[key] ? vm.statis[key]['stop'] : 0;
                    if (stop == 0) {
                        var body = {
                            projectId: vm.project.id,
                            depotId: Number(vm.getFilters().depot),
                            origin: Number(key),
                            destination: Number(Object.entries(vm.statis)[0][0])
                        };
                        var params = null;
                        Route.territoryMerge(params, body, function () {
                            reloadRoutePlanCore();
                        }, function () {});
                        return;
                    }
                    _transferStopDialog();
                }
            });

            function _transferStopDialog() {
                var transferStop = RoutePlanningModalFactory.stopsTransferDialog(vm.project.id, vm.getFilters().depot, key, value, vm.sharedData.dataStyle.depots[vm.getFilters().depot].territories);
                transferStop.result.then(function () {
                    reloadRoutePlanCore();
                });
            }
        }

        function onTerritoryNameClick(key) {
            vm.editModeName = {};
            vm.editModeName[key] = true;
            $timeout(function(){
                angular.element('#territory-name'+key).trigger('focus');
            }, 50);
        }

        function convertStopDurationMsIntoMinutes(data) {
            data.features.forEach(function(feature){
                feature.properties.daysLocked = feature.properties.daysLocked !== null ? true : false;
                feature.properties.depotLocked = feature.properties.depotLocked !== null ? true : false;
                feature.properties.territoryLocked = feature.properties.territoryLocked !== null ? true : false;
                feature.properties.weeksLocked = feature.properties.weeksLocked !== null ? true : false;
                feature.properties.inStopDuration = feature.properties.inStopDuration ? feature.properties.inStopDuration / vm.milisMultiple : 0;
            });
            return data;
        }

        function startDrawing(objectName) {
            if (vm.sharedData.isDrawingMode) {
                return;
            }

            if (objectName === 'polygon') {
                vm.sharedData.polygon.enable();
            }

            if (objectName === 'rectangle') {
                vm.sharedData.rectangle.enable();
            }

            if (objectName === 'circle') {
                vm.sharedData.circle.enable();
            }

            vm.sharedData.isDrawingMode = true;
        }

        function saveNewDepotLatLng() {
            vm.isSavingMarker = true;
            var depotId = vm.originalStop.options.id;
            Depot.get({projectId: vm.project.id, id:depotId}, _onGetDepotSuccess, cancelMoveStop);
            function _onGetDepotSuccess(depot) {
                depot.lat = vm.newLat;
                depot.lon = vm.newLong;
                Depot.update({projectId: vm.project.id},depot, _onUpdateDepotSuccess, cancelMoveStop);
            }

            function _onUpdateDepotSuccess() {
                vm.isSavingMarker = false;
                vm.originalStop.setLatLng([vm.newLat, vm.newLong]);
                vm.depots = vm.depots.map(function(depot){
                    if(depot.id == depotId){
                        depot.lat = vm.newLat;
                        depot.lon = vm.newLong;
                    }
                    return depot;
                });

                loadProjectErrorSummary(true);
                fetchDepotStatsSummary();
                cancelMoveStop();
            }
        }

        function onSyncSuccess() {
            firstLoad = true;
            reloadRoutePlanCore();
        }

        function drawDepotGravity(depotGravityLayers) {
            if (vm.sharedData.depotGravityLayer) {
                vm.sharedData.depotGravityLayer.clearLayers();
            }

            if (!depotGravityLayers.length || !depotGravityLayers) {
                return;
            }

            depotGravityLayers.forEach(function(eachLayer){
                eachLayer.addTo(vm.sharedData.depotGravityLayer).bringToBack();
            });
        }

        function onSavedSettingPoi(res) {
        }

        function addDepotGravityButton() {
            if(vm.sharedData.currentView === CONST.DEPOT) {
                if(vm.depotGravityButton) {
                    vm.depotGravityButton.removeFrom(vm.sharedData.map);
                }
                vm.depotGravityButton = L.easyButton('<img id="depot-gravity" src="content/images/depot-gravity-icon.svg" style="margin-top:-5px"/>', function (btn, map) {
                    vm.showDepotGravity = !vm.showDepotGravity;
                    $scope.$apply();
                }, 'Depot Gravity', { position: 'topright' }).addTo(vm.sharedData.map);
                vm.enableDepotGravity = true;
            } else {
                if(vm.depotGravityButton) {
                    vm.depotGravityButton.removeFrom(vm.sharedData.map);
                }
                if (vm.sharedData.depotGravityLayer) {
                    vm.sharedData.depotGravityLayer.clearLayers();
                }
                vm.showDepotGravity = false;
                vm.enableDepotGravity = false;
            }
        }

        function onChangeModes(mode) {
            vm.onSelectedMode = mode;
        }

        function broadcastOnChangeMode() {
            vm.onSelectedMode = 'title';
        }

        function showHideAdministrativeArea(view, key, status) {
            if (!vm.areaByPoi[view][key]) {
                vm.areaByPoi[view][key] = null;
            }

            // remove kecamatan layer
            if (!status) {
                vm.areaByPoi[view][key].removeFrom(vm.sharedData.map);
                return;
            }

            var points = [];
            vm.areaLoading[key] = true;
            points = vm.routeGeom.features.filter(function(each){
                if(view === 'depot') {
                    return each.properties.depotId == key;
                } else {
                    return each.properties.routes.find(function(eachRoute){
                        return eachRoute[view] == key;
                    });
                }
            });

            var request = {
                "areaType": "KELURAHAN",
                "points": points.map(function(each){
                    return {
                        lat: each.geometry.coordinates[1],
                        lon: each.geometry.coordinates[0],
                    };
                })
            };

            AdmarService.getAreaByPoints(request, function(data){
                vm.areaLoading[key] = false;
                function styleAreaByPoints() {
                    var style = {
                        color: '',
                        fillColor: '',
                        opacity: 0.5,
                        fillOpacity: 0.6,
                        weight: 1
                    };
                    if(view === 'depot') {
                        style.color = vm.mapProp.style.depots[key].properties.color;
                        style.fillColor = vm.mapProp.style.depots[key].properties.color;
                    } else if (view === 'day') {
                        style.color = vm.mapProp.style.days[key].color;
                        style.fillColor = vm.mapProp.style.days[key].color;
                    } else if (view === 'territory'){
                        style.color = vm.mapProp.style.depots[vm.sharedData.filters.depot].territories[key].color;
                        style.fillColor = vm.mapProp.style.depots[vm.sharedData.filters.depot].territories[key].color;
                    } else if (view === 'week'){
                        style.color = vm.mapProp.style.depots[vm.sharedData.filters.depot].weeks[key].color;
                        style.fillColor = vm.mapProp.style.depots[vm.sharedData.filters.depot].weeks[key].color;
                    }
                    return style;
                }

                vm.areaByPoi[view][key] = L.geoJSON(data, {style: styleAreaByPoints})
                    .bindTooltip(function(layer){
                        return layer.feature.properties.name;
                    })
                    .addTo(vm.sharedData.areaByPoiLayer);
            }, function(){
                vm.areaLoading[key] = false;
            });

        }

        function onDurationChange() {
            vm.invalidDuration = vm.project.props.useCapacity && vm.project.props.reloadDuration <= 0 ? true : false;
        }

        function createConvexHull(stops) {
            var pathStyle = {
                color: 'black',
                weight: 1,
                fillOpacity: 0.6,
            };

            if (!stops.features || !stops.features.length) {
                return;
            }

            if (vm.sharedData.convexHull) {
                vm.sharedData.convexHull.clearLayers();
            }

            if (vm.sharedData.currentView === CONST.DEPOT) {
                var groupStopByDepot = stops.features.reduce(function (group, feature) {
                    var depotId = feature.properties.depotId;
                    if (!group.hasOwnProperty(depotId)) {
                        group[depotId] = { type: 'FeatureCollection', features: [] };
                    }

                    group[depotId].features = stops.features.filter(function (feature) {
                        return feature.properties.depotId === depotId;
                    });

                    return group;
                }, {});

                Object.keys(groupStopByDepot).forEach(function (key) {
                    var depotColor = vm.sharedData.dataStyle.depots[key].properties.color;
                    var convexLayer = turf.convex(groupStopByDepot[key]);

                    pathStyle.color = depotColor ? depotColor : pathStyle.color;

                    var configLayer = {
                        style: function () {
                            return pathStyle;
                        },
                    };

                    if (convexLayer) {
                        var areaInKm = (turf.area(convexLayer)/(1000000)).toFixed(2);
                        var depotName = vm.sharedData.depots.find(function(x){ return x.id == key;}).name;
                        var content = '';
                        content += '<div><span style="font-weight:bold">Depot: </span><span>'+depotName+'</span><div></div>';
                        content += '<div><span style="font-weight:bold">Area: </span><span>'+areaInKm+' km²</span><div></div>';
                        var tooltipTimeOut = null;

                        var depotLayer = L.geoJSON(convexLayer, configLayer)
                            .addTo(vm.sharedData.convexHull)
                            .bindTooltip(content)
                            .on('mouseover', function(e) {
                                depotLayer.closeTooltip();
                                tooltipTimeOut = setTimeout(function(){depotLayer.openTooltip();},2000);
                            })
                            .on('mouseout', function(e) {
                                depotLayer.closeTooltip();
                                clearTimeout(tooltipTimeOut);
                            })
                            .bringToBack();
                    }
                });
            }

            if (vm.sharedData.currentView === CONST.TERRITORY) {
                var depotId = vm.sharedData.filters.depot;
                var territories = vm.sharedData.dataStyle.depots[depotId].territories;

                if (territories) {
                    var groupStopByTerritory = Object.keys(territories).reduce(function (group, key) {
                        if (!group.hasOwnProperty(key)) {
                            group[key] = { type: 'FeatureCollection', features: [] };
                        }

                        group[key].features = stops.features.filter(function (feature) {
                            var routes = feature.properties.routes;
                            return routes.find(function (r) {
                                return r.territory == key;
                            });
                        });

                        return group;
                    }, {});

                    Object.keys(groupStopByTerritory).forEach(function (key) {
                        var convexLayer = turf.convex(groupStopByTerritory[key]);
                        var territoryColor = territories[key].color;

                        pathStyle.color = territoryColor ? territoryColor : pathStyle.color;

                        var configLayer = {
                            style: function () {
                                return pathStyle;
                            },
                        };
                        if (convexLayer) {
                            var areaInKm = (turf.area(convexLayer)/(1000000)).toFixed(2);
                            var content = '';
                            content += '<div><span style="font-weight:bold">Territory: </span><span>'+key+'</span><div></div>';
                            content += '<div><span style="font-weight:bold">Area: </span><span>'+areaInKm+' km²</span><div></div>';
                            var tooltipTimeOut = null;

                            var territoryLayer = L.geoJSON(convexLayer, configLayer)
                                .addTo(vm.sharedData.convexHull)
                                .bindTooltip(content)
                                .on('mouseover', function (e) {
                                    territoryLayer.closeTooltip();
                                    tooltipTimeOut = setTimeout(function () {
                                        territoryLayer.openTooltip();
                                    }, 2000);
                                })
                                .on('mouseout', function (e) {
                                    territoryLayer.closeTooltip();
                                    clearTimeout(tooltipTimeOut);
                                })
                                .bringToBack();
                        }
                    });
                }
            }

            if (vm.sharedData.currentView === CONST.WEEK) {
                var depotId = vm.sharedData.filters.depot;
                var weeks = vm.sharedData.dataStyle.depots[depotId].weeks;

                if (weeks) {
                    var groupStopByWeek = Object.keys(weeks).reduce(function (group, key) {
                        if (!group.hasOwnProperty(key)) {
                            group[key] = { type: 'FeatureCollection', features: [] };
                        }

                        group[key].features = stops.features.filter(function (feature) {
                            var routes = feature.properties.routes;
                            return routes.find(function (r) {
                                return r.week == key;
                            });
                        });

                        return group;
                    }, {});

                    Object.keys(groupStopByWeek).forEach(function (key) {
                        var convexLayer = turf.convex(groupStopByWeek[key]);
                        var weekColor = weeks[key].color;

                        pathStyle.color = weekColor ? weekColor : pathStyle.color;

                        var configLayer = {
                            style: function () {
                                return pathStyle;
                            },
                        };

                        if (convexLayer) {
                            var areaInKm = (turf.area(convexLayer)/(1000000)).toFixed(2);
                            var content = '';
                            content += '<div><span style="font-weight:bold">Week: </span><span>'+key+'</span><div></div>';
                            content += '<div><span style="font-weight:bold">Area: </span><span>'+areaInKm+' km²</span><div></div>';
                            var tooltipTimeOut = null;

                            var weekLayer = L.geoJSON(convexLayer, configLayer)
                                .addTo(vm.sharedData.convexHull)
                                .bindTooltip(content)
                                .on('mouseover', function (e) {
                                    weekLayer.closeTooltip();
                                    tooltipTimeOut = setTimeout(function () {
                                        weekLayer.openTooltip();
                                    }, 2000);
                                })
                                .on('mouseout', function (e) {
                                    weekLayer.closeTooltip();
                                    clearTimeout(tooltipTimeOut);
                                })
                                .bringToBack();
                        }
                    });
                }
            }

            if (vm.sharedData.currentView === CONST.DAY) {
                var depotId = vm.sharedData.filters.depot;
                var days = vm.sharedData.dataStyle.days;

                if (days) {
                    var groupStopByDay = Object.keys(days).reduce(function (group, key) {
                        if (!group.hasOwnProperty(key)) {
                            group[key] = { type: 'FeatureCollection', features: [] };
                        }

                        group[key].features = stops.features.filter(function (feature) {
                            var routes = feature.properties.routes;
                            return routes.find(function (r) {
                                return r.day == key;
                            });
                        });

                        return group;
                    }, {});

                    Object.keys(groupStopByDay).forEach(function (key) {
                        var convexLayer = turf.convex(groupStopByDay[key]);
                        var dayColor = days[key].color;

                        pathStyle.color = dayColor ? dayColor : pathStyle.color;

                        var configLayer = {
                            style: function () {
                                return pathStyle;
                            },
                        };

                        if (convexLayer) {
                            var areaInKm = (turf.area(convexLayer)/(1000000)).toFixed(2);
                            var content = '';
                            content += '<div><span style="font-weight:bold">Day: </span><span>'+key+'</span><div></div>';
                            content += '<div><span style="font-weight:bold">Area: </span><span>'+areaInKm+' km²</span><div></div>';
                            var tooltipTimeOut = null;

                            var dayLayer = L.geoJSON(convexLayer, configLayer)
                                .addTo(vm.sharedData.convexHull)
                                .bindTooltip(content)
                                .on('mouseover', function (e) {
                                    dayLayer.closeTooltip();
                                    tooltipTimeOut = setTimeout(function () {
                                        dayLayer.openTooltip();
                                    }, 2000);
                                })
                                .on('mouseout', function (e) {
                                    dayLayer.closeTooltip();
                                    clearTimeout(tooltipTimeOut);
                                })
                                .bringToBack();
                        }
                    });
                }
            }

            vm.sharedData.convexHull.on('add', function(e) {
                vm.sharedData.convexHull.bringToBack();
            });
        }

        function openOutletLockDialog (feature) {
            var changeFreqDialog = RoutePlanningModalFactory.outletLockDialog(feature, vm.sharedData.selectedRoute, vm.project.id);
            changeFreqDialog.result.then(function () {;
                loadGeoPoin(true);
            });
        }

        function checkAllOutletLock() {
            if (vm.routeGeom) {
                var someHaveLocked = vm.routeGeom.features.some(function (route) {
                    return route.properties.territoryLocked || route.properties.depotLocked || route.properties.weeksLocked || route.properties.daysLocked;
                });

                if (someHaveLocked) {
                    if (
                        (vm.sharedData.currentView == CONST.TERRITORY && vm.allOutletLock.depot) ||
                        (vm.sharedData.currentView == CONST.DEPOT && vm.allOutletLock.territory) ||
                        (vm.sharedData.currentView == CONST.WEEK && vm.allOutletLock.week) ||
                        (vm.sharedData.currentView == CONST.DAY && vm.allOutletLock.day)
                    ) { return 'lock-current'; }

                    return 'lock';
                }
                return 'unlock';
            }
        }

        function checkOutletLock(route) {
            if (
                !route.properties.depotLocked &&
                !route.properties.territoryLocked &&
                !route.properties.weeksLocked &&
                !route.properties.daysLocked
            ) { return 'unlock'; }

            if (
                (vm.sharedData.currentView === CONST.TERRITORY && route.properties.territoryLocked) ||
                (vm.sharedData.currentView === CONST.DEPOT && route.properties.depotLocked) ||
                (vm.sharedData.currentView === CONST.WEEK && route.properties.weeksLocked) ||
                (vm.sharedData.currentView === CONST.DAY && route.properties.daysLocked)
            ) { return 'lock-current'; }

            return 'lock';
        }

        function saveEachLock(route) {
            var bodyRequest = {
                ids: [route.properties.stopId.toString()],
                depotLocked: route.properties.depotLocked ? route.properties.depotLocked : false,
                territoryLocked: route.properties.territoryLocked ? route.properties.territoryLocked : false,
                weeksLocked: route.properties.weeksLocked ? route.properties.weeksLocked : false,
                daysLocked: route.properties.daysLocked ? route.properties.daysLocked : false
            };
            _saveLockStops(bodyRequest);
        }

        function saveAllLock() {
            vm.filteredGeom = $filter('filter')(vm.routeGeom.features, vm.filterFn);
            vm.filteredGeom.forEach(function (route) {
                route.properties.depotLocked = vm.allOutletLock.depot;
                route.properties.territoryLocked = vm.allOutletLock.territory;
                route.properties.weeksLocked = vm.allOutletLock.week;
                route.properties.daysLocked = vm.allOutletLock.day;
            });

            var bodyRequest = {
                ids: vm.filteredGeom.map(function (item) {
                    return item.properties.stopId.toString();
                }),
                depotLocked: vm.allOutletLock.depot ? vm.allOutletLock.depot : false,
                territoryLocked: vm.allOutletLock.territory ? vm.allOutletLock.territory : false,
                weeksLocked: vm.allOutletLock.week ? vm.allOutletLock.week : false,
                daysLocked: vm.allOutletLock.day ? vm.allOutletLock.day : false
            };
            _saveLockStops(bodyRequest);
        }

        function _saveLockStops(bodyRequest) {
            var queryString = { projectId: vm.project.id };

            Project.lockStops(queryString, bodyRequest, _success, _error);

            function _success() {
                vm.allRouteChecked = false;
                loadGeoPoin(true);
            }

            function _error(err) {
                console.error(err.message);
            }
        }

        function openStopImportDialog(parsedFileResult) {
            var stopImportDialog = RoutePlanningModalFactory.importStopModal(parsedFileResult, vm.project);

            stopImportDialog.result.then(
                function (result) {
                    if (result == 'RELOAD') {
                        if (!vm.oneClickFeatureToggle) {
                            reloadRoutePlanCore();
                            return;
                        }
                        var importStopModalOptimize = RoutePlanningModalFactory.importStopModalOptimize();
                        importStopModalOptimize.result.then(
                            function () {
                                reloadRoutePlanCore().then(function(){
                                    oneClickOptimization();
                                });
                            },
                            function () {
                                reloadRoutePlanCore();
                            }
                        );
                    }
                },
                function () {
                    openImportDialog();
                }
            );
        }

        function openRouteImportDialog(parsedFileResult, depot) {
            var routeImportDialog = RoutePlanningModalFactory.importRouteModal(parsedFileResult, depot, vm.project, vm.mapProp);

            routeImportDialog.result.then(
                function (result) {
                    if (result.reload) {
                        generateDailyRouteStat(vm.project.id, result.depotId);
                    }
                },
                function () {
                    openImportDialog();
                }
            );
        }

        function filterFn(item, index, array) {
            if (!vm.searchQuery) {
                return true;
            }

            var keywords = {
                stopExternalId: vm.searchQuery.properties.stopExternalId ? vm.searchQuery.properties.stopExternalId : '',
                stopName: vm.searchQuery.properties.stopName ? vm.searchQuery.properties.stopName : '',
                notes: vm.searchQuery.properties.stopProps && vm.searchQuery.properties.stopProps.notes ? vm.searchQuery.properties.stopProps.notes : [],
                value: vm.searchQuery.properties.stopProps && vm.searchQuery.properties.stopProps.value ? vm.searchQuery.properties.stopProps.value : '',
                value2: vm.searchQuery.properties.stopProps && vm.searchQuery.properties.stopProps.value2 ? vm.searchQuery.properties.stopProps.value2 : '',
                value3: vm.searchQuery.properties.stopProps && vm.searchQuery.properties.stopProps.value3 ? vm.searchQuery.properties.stopProps.value3 : '',
                inStopDuration: vm.searchQuery.properties.inStopDuration ? vm.searchQuery.properties.inStopDuration : '',
                stopVisitFreq: vm.searchQuery.properties.stopVisitFreq ? vm.searchQuery.properties.stopVisitFreq : [],
                timeWindowStr: vm.searchQuery.properties.stopProps.timeWindowStr ? vm.searchQuery.properties.stopProps.timeWindowStr : '',
            };

            function _filterStopExternalId() {
                if (keywords.stopExternalId) {
                    return item.properties.stopExternalId.toLowerCase().includes(keywords.stopExternalId.toLowerCase());
                }
                return true;
            }

            function _filterStopName() {
                if (keywords.stopName) {
                    return item.properties.stopName.toLowerCase().includes(keywords.stopName.toLowerCase());
                }
                return true;
            }

            function _filterValue() {
                if (keywords.value) {
                    var value = String(item.properties.stopProps.value);
                    return value.includes(keywords.value);
                }
                return true;
            }

            function _filterValue2() {
                if (keywords.value2) {
                    var value = String(item.properties.stopProps.value2);
                    return value.includes(keywords.value2);
                }
                return true;
            }

            function _filterValue3() {
                if (keywords.value3) {
                    var value = String(item.properties.stopProps.value3);
                    return value.includes(keywords.value3);
                }
                return true;
            }

            function _filterInStopDuration() {
                if (keywords.inStopDuration) {
                    var inStopDuration = String(item.properties.inStopDuration);
                    return inStopDuration.includes(keywords.inStopDuration);
                }
                return true;
            }

            function _filterNotes() {
                if (keywords.notes.length) {
                    var notes = item.properties.stopProps.notes;
                    return keywords.notes.some(function(k){
                        return notes.toLowerCase().includes(k.toLowerCase());
                    });
                }
                return true;
            }

            function _filterStopVisitFreq() {
                if (keywords.stopVisitFreq.length) {
                    var visitFreq = item.properties.stopVisitFreq;
                    return keywords.stopVisitFreq.some(function(k){
                        return k === visitFreq;
                    });
                }
                return true;
            }

            function _filterTimeWindow() {
                if (keywords.timeWindowStr === 'all') {
                    return true;
                }
                if (keywords.timeWindowStr.length) {
                    return item.properties.stopProps.timeWindowStr.toLowerCase().includes(keywords.timeWindowStr.toLowerCase());
                }else {
                    return item.properties.stopProps.timeWindowStr == ''
                }
            }


            return _filterStopExternalId() &&
                _filterStopName() &&
                _filterValue() &&
                _filterValue2() &&
                _filterValue3() &&
                _filterInStopDuration() &&
                _filterNotes() &&
                _filterStopVisitFreq() &&
                _filterTimeWindow();

        }

        function addNotesFilter(keyEvent) {
            if (keyEvent.which === 13) {
                var checkKeyword = vm.searchQuery.properties.stopProps.notes.some(function (x) {
                    return x === vm.querySingleNotes;
                });
                if (checkKeyword) {
                    vm.querySingleNotes = '';
                    return;
                }
                vm.searchQuery.properties.stopProps.notes.push(vm.querySingleNotes.toLocaleLowerCase());
                vm.querySingleNotes = '';
            }
        }

        function removeNotesFilter(index) {
            vm.searchQuery.properties.stopProps.notes = vm.searchQuery.properties.stopProps.notes.filter(function (x, idx) {
                return idx !== index;
            });
        }

        /**
         * check filter on table has any filter && limit > 20
         */
        function resetLimitByFilter() {
            if (
                (vm.searchQuery.properties.inStopDuration.length === 0 ||
                vm.searchQuery.properties.stopExternalId.length === 0 ||
                vm.searchQuery.properties.stopName.length === 0 ||
                vm.searchQuery.properties.stopProps.notes.length === 0 ||
                vm.searchQuery.properties.stopProps.value.length === 0 ||
                vm.searchQuery.properties.stopProps.timeWindowStr.length === 0 ||
                vm.searchQuery.properties.stopVisitFreq.length === 0) && vm.limit > 20
            ) {
                vm.limit = 20;
            }
        }

        function openDeleteRouteStopDialog (feature) {
            var changeFreqDialog = RoutePlanningModalFactory.deleteStopDialog(feature, vm.sharedData.selectedRoute);
            changeFreqDialog.result.then(function () {
                vm.sharedData.weekTab = 1;
                reloadRoutePlanCore();
            });
        }

        function onTimeChangedOutlet(start, end) {
            var invalidTime = start == null || end == null || start > end ? true : false
            if (invalidTime) {
                return
            }
            loadRouteCheckBoxStatus();
            resetLimitByFilter();
        }

        function printTimeWindow(prop) {
            if (!prop.stopProps.timeWindow) {
                return '';
            }
            var startTime = moment.utc().startOf('day').add(prop.stopProps.timeWindow.startTime, 'seconds').format('HH:mm');
            var endTime = moment.utc().startOf('day').add(prop.stopProps.timeWindow.endTime, 'seconds').format('HH:mm');
            return startTime+ '-' +  endTime; // '08:00-18:00'
        }

        function _intervalPolling() {
            if (!vm.oneClickFeatureToggle) {
                return;
            }
            Project.balancingStatus({projectId: vm.project.id}).$promise.then(_onSuccess);

            /**
             * @param {IResBalanceStatus} res
             */
            function _onSuccess(res) {
                if (!res.done) {
                    oneClickOptimization(res)
                }
            }
        }

        function openUnassignDialog (stopIds) {
            var unassignDialog = RoutePlanningModalFactory.unassignStopModal(stopIds, vm.project.id);
            unassignDialog.result.then(function () {
                reloadRoutePlanCore();
            });
        }

        function assignToRoute(stopIds) {
            OutletAssignmentService.assign({id: vm.project.id},{outletIds: stopIds}).$promise.then(
                function(){
                    reloadRoutePlanCore();
                }, function(){}
            );
        }

        function autoBalanceByDepot (balanceBy, selectedValue) {
            vm.disableOnLoad = true;

            confirmBalancing(function () {
                var queryString = { projectId: vm.project.id };
                var bodyRequest = {
                    "balancingBy": balanceBy,
                    "depotBalancingFactor": vm.onSelectedMode === 'balanceDepotRadius' ? 100 : 0,
                    "cluster": "NONE",
                    selectedValue: selectedValue
                };

                Route.balancingDepot(queryString, bodyRequest, onAutoBalanceSuccess, onAutoBalanceError);
            });
        }

        function autoBalanceByTerritory (balanceBy, selectedValue) {
            vm.disableOnLoad = true;

            confirmBalancing(function () {
                var territoryCount = Object.keys(vm.getDataStyles().depots[vm.getFilters().depot].territories).length;
                var queryString = {
                    "projectId.equals": vm.project.id,
                    "depotId.equals": vm.getFilters().depot,
                    "week.in": vm.getFilters().week,
                    "day.in": vm.getFilters().day,
                };

                if (vm.getFilters().week) {
                    queryString["week.in"] = vm.getFilters().week;
                }

                if (vm.getFilters().day) {
                    queryString["day.in"] = vm.getFilters().day;
                }

                var bodyRequest = {
                    balancingBy: balanceBy,
                    depotBalancingFactor: 0,
                    size: territoryCount,
                    doNotRefreshDRS: true,
                    cluster: "NONE",
                    type: "TERRITORY_ODL",
                    selectedValue: selectedValue
                };

                Route.balancingTerritory(queryString, bodyRequest, onAutoBalanceSuccess, onAutoBalanceError);
            });
        }

        function autoBalanceByWeek (balanceBy, selectedValue) {
            vm.disableOnLoad = true;

            confirmBalancing(function() {
                var weekCount = Object.keys(vm.getDataStyles().depots[vm.getFilters().depot].weeks).length;
                var queryString = {
                    "projectId.equals": vm.project.id,
                    "depotId.equals": vm.getFilters().depot,
                    "territory.in": vm.getFilters().territory,
                    "day.in": vm.getFilters().day,
                };

                if (vm.getFilters().territory) {
                    queryString["territory.in"] = vm.getFilters().territory;
                }

                if (vm.getFilters().day) {
                    queryString["day.in"] = vm.getFilters().day;
                }

                var bodyRequest = {
                    balancingBy: balanceBy,
                    depotBalancingFactor: 0,
                    size: weekCount,
                    type: "WEEK_ODL",
                    selectedValue: selectedValue
                };

                Route.balancingWeek(queryString, bodyRequest, onAutoBalanceSuccess, onAutoBalanceError);
            });
        }

        function autoBalanceByDay (balanceBy, selectedValue) {
            vm.disableOnLoad = true;

            confirmBalancing(function() {
                var dayCount = Object.keys(vm.getDataStyles().days).length;
                var queryString = {
                    "projectId.equals": vm.project.id,
                    "depotId.equals": vm.getFilters().depot,
                    "week.in": vm.getFilters().week,
                    "territory.in": vm.getFilters().territory,
                };

                if (vm.getFilters().territory) {
                    queryString["territory.in"] = vm.getFilters().territory;
                }

                if (vm.getFilters().week) {
                    queryString["week.in"] = vm.getFilters().week;
                }

                var bodyRequest = {
                    balancingBy: balanceBy,
                    depotBalancingFactor: 0,
                    size: dayCount,
                    type: "DAY_ODL",
                    selectedValue: selectedValue
                };

                Route.balancingDay(queryString, bodyRequest, onAutoBalanceSuccess, onAutoBalanceError);
            });
        }

        function loadProjectErrorSummary(reload) {
            var depotIds = vm.sharedData.depots.map(function(d) {return d.id;});

            if (!depotIds || !depotIds.length) {
                vm.isLoadingProjectErrorSummary = false;
                return;
            }

            if (reload) {
                vm.isLoadingProjectErrorSummary = true;
                var params = {
                    projectId: vm.project.id,
                };

                var useCapacity = vm.project.props.useCapacity;

                var overtimeRequests = depotIds.map(function(id) {
                    return DailyRouteStatService.getListOvertimeSummaries({projectId: vm.project.id, depotId: id, refresh: useCapacity ? false : true}).$promise;
                });
                var overSizeRequests = depotIds.map(function(id) {
                    return DailyRouteStatService.getListOversizeSummaries({projectId: vm.project.id, depotId: id}).$promise;
                });

                var request = {
                    overtime: $q.all(overtimeRequests),
                    oversize: $q.all(overSizeRequests),
                    overcapacity: DailyRouteStatService.getListOvercapacitySummaries(params).$promise,
                    unassign: Project.getUnassignStop(params).$promise
                };

                $q.all(request).then(function(response){
                    vm.isLoadingProjectErrorSummary = false;
                    response.overtime = flatten(response.overtime);
                    response.oversize = flatten(response.oversize);

                    vm.projectErrorSummary.overCapacity = response.overcapacity;
                    vm.projectErrorSummary.overStop = response.oversize;
                    vm.projectErrorSummary.overtime = response.overtime;
                    vm.projectErrorSummary.unassignStops = response.unassign;

                    vm.projectErrorSummary.overCapacityCount = _getTotalCount(vm.projectErrorSummary.overCapacity);
                    vm.projectErrorSummary.overtimeCount = _getTotalCount(vm.projectErrorSummary.overtime);
                    vm.projectErrorSummary.overStopCount = _getTotalCount(vm.projectErrorSummary.overStop);
                    _createUnassignStop();

                    vm.hasRouteViolation = vm.projectErrorSummary.overCapacityCount + vm.projectErrorSummary.overStopCount + vm.projectErrorSummary.overtimeCount + vm.projectErrorSummary.unassignStopCount >= 1;

                }, function(){
                    vm.isLoadingProjectErrorSummary = false;
                });
            } else {
                vm.projectErrorSummary.overCapacityCount = _getTotalCount(vm.projectErrorSummary.overCapacity);
                vm.projectErrorSummary.overtimeCount = _getTotalCount(vm.projectErrorSummary.overtime);
                vm.projectErrorSummary.overStopCount = _getTotalCount(vm.projectErrorSummary.overStop);
                _createUnassignStop();
                vm.hasRouteViolation = vm.projectErrorSummary.overCapacityCount + vm.projectErrorSummary.overStopCount + vm.projectErrorSummary.overtimeCount + vm.projectErrorSummary.unassignStopCount >= 1;
            }

            function _getTotalCount(dataSummary) {
                if (!dataSummary.length) {
                    return 0;
                }

                var totalCountAllDepot = dataSummary.reduce(function(sum, currentValue) {
                    return sum + currentValue.totalRoute;
                }, 0);

                var totalCountDepotById = vm.sharedData.filters.depot ? dataSummary.find(function(ds){
                    return ds.depotId == vm.sharedData.filters.depot;
                }).totalRoute : 0;

                return vm.sharedData.filters.depot ? totalCountDepotById : totalCountAllDepot;
            }

            function _createUnassignStop() {
                var depotId = vm.sharedData.filters.depot;
                var unassignStops = [];

                vm.sharedData.selectedUnassignStop = new Set();
                vm.sharedData.unassignLayer.clearLayers();

                vm.projectErrorSummary.unassignStopCount = 0;

                var geojsonFeature = {
                    type: "FeatureCollection",
                    features: [
                    ]
                };

                if (vm.projectErrorSummary.unassignStops.length) {

                    unassignStops = depotId ? vm.projectErrorSummary.unassignStops.filter(function(u) {
                        return u.depot.id == depotId;
                    }) : unassignStops;

                    vm.projectErrorSummary.unassignStopCount = unassignStops.length;

                    unassignStops.forEach(function(x){
                        var props = x.props;
                        geojsonFeature.features.push(
                            {
                                type: "Feature",
                                properties: {
                                    id: x.id,
                                    name: x.name,
                                    reason: x.reason,
                                    depot: x.depot,
                                    externalId: x.externalId,
                                    visitFreq: x.visitFreq,
                                    value: props.value ? props.value : 0,
                                    value2: props.value2 ? props.value2 : 0,
                                    value3: props.value3 ? props.value3 : 0,
                                },
                                geometry: {
                                    coordinates: [
                                        x.lon,
                                        x.lat
                                    ],
                                    type: "Point"
                                }
                            }
                        );
                    });
                }

                vm.sharedData.unassignLayer.addData(geojsonFeature);
                vm.sharedData.unassignLayer.on('add', function(e) {
                    vm.sharedData.unassignLayer.bringToBack();
                });
            }

            function flatten(array) {
                var result = [];
                for (var i = 0; i < array.length; i++) {
                    if (Array.isArray(array[i])) {
                        result = result.concat(flatten(array[i]));
                    } else {
                        result.push(array[i]);
                    }
                }
                return result;
            }

        }

        function generateDailyRouteStat(projectId, depotId) {
            var useCapacity = vm.project.props.useCapacity;
            var queryParam = {
                projectId: projectId,
                refresh: useCapacity ? false : true
            };
            var req = {
                depotIdVm: {
                    depotId: depotId
                }
            };
            var body = depotId ? req : null;
            DailyRouteStatService.generateDailyRouteStat(queryParam, body).$promise.then(reloadRoutePlanCore());
        }

        function openBulkDeleteTerritoryDialog() {
            var params = {
                project: vm.project,
                territories: vm.getDataStyles().depots[vm.getFilters().depot].territories,
                statistic: vm.statis,
                depot: vm.getFilters().depot
            };

            var bulkDeleteTerritoryDialog = RoutePlanningModalFactory.openBulkDeleteTerritoryDialog(params);
            bulkDeleteTerritoryDialog.result.then(function () {
                reloadRoutePlanCore();
            });
        }
    }
})();
