(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'User', 'JhiLanguageService', 'Workplace'];

    function UserManagementDialogController ($stateParams, $uibModalInstance, entity, User, JhiLanguageService, Workplace) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_ONE_TIME_USER', 'ROLE_M2M'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;
        vm.workplaces = [];
        vm.activeStatusOrigin = entity.activated;
        vm.workplaceIdOrigin = entity.workplace ? entity.workplace.id : null;
        vm.validateWorkplaceLimit = validateWorkplaceLimit;
        vm.showWorkplaceLimit = false;


        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        loadWorkspace();

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
            vm.isSaving = true;
            vm.user.phoneNumber = vm.user.phoneNumber ? vm.user.phoneNumber : null;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }

        function loadWorkspace() {
            Workplace.query({size: 2000, sort: 'name,asc'}, function(data) {
                vm.workplaces = data;
            });
            validateWorkplaceLimit(vm.user.activated);
        }

        function validateWorkplaceLimit(status) {
            if (!status || !vm.user.workplace) {
                vm.showWorkplaceLimit = false;
                return;
            }

            var workplaceChanged = vm.workplaceIdOrigin !== vm.user.workplace.id;
            var selectedWorkplace = vm.workplaces.find(function (wp) {
                return wp.id === vm.user.workplace.id;
            });
            var showLimitCriteria = !vm.user.id || (vm.user.id && !vm.activeStatusOrigin);

            if (workplaceChanged) {
                vm.showWorkplaceLimit =
                    selectedWorkplace.activeUserAccountLimit === selectedWorkplace.totalOfActiveUsers;
            } else if (showLimitCriteria) {
                vm.showWorkplaceLimit =
                    selectedWorkplace.activeUserAccountLimit === selectedWorkplace.totalOfActiveUsers;
            } else {
                vm.showWorkplaceLimit = false;
            }
        }
    }
})();
