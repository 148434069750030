angular.module('stpApp').directive('indeterminate', function () {
    return {
        restrict: 'A',
        scope: {
            indeterminate: '=',
        },
        link: function (scope, element) {
            scope.$watch('indeterminate', function (value) {
                element[0].indeterminate = !!value;
            });
        },
    };
});
