(function() {
    'use strict';

    angular
        .module('stpApp')
        .controller('UserManagementWorkplaceLimitController', UserManagementWorkplaceLimitController);

    UserManagementWorkplaceLimitController.$inject = ['$uibModalInstance', 'workplaceLimit', '$state'];

    function UserManagementWorkplaceLimitController ($uibModalInstance, workplaceLimit, $state) {
        var vm = this;

        vm.workplaceLimit = workplaceLimit;
        vm.close = close;
        vm.gotoWorkplace = gotoWorkplace;

        function close () {
            $uibModalInstance.dismiss('cancel');
        }

        function gotoWorkplace() {
            $uibModalInstance.dismiss('cancel');
            $state.go('workplace', null, { reload: true });
        }
    }
})();
