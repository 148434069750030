(function() {
    'use strict';
    angular
        .module('stpApp')
        .factory('Workplace', Workplace);

    Workplace.$inject = ['$resource', 'DateUtils'];

    function Workplace ($resource, DateUtils) {
        var resourceUrl =  'uaas/' + 'api/workplaces/:id';

        return $resource(resourceUrl, {}, {
            'query': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.forEach(function (d) {
                            d.validUntil = DateUtils.convertDateTimeFromServer(d.validUntil);
                        });
                    }
                    return data;
                }
            },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.validUntil = DateUtils.convertDateTimeFromServer(data.validUntil);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
        });
    }
})();
