(function () {
    'use strict';

    angular
        .module('stpApp')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', 'Auth', 'Principal', 'ProfileService', '$rootScope', 'LoginFactory'];

    function NavbarController($state, Auth, Principal, ProfileService, $rootScope, LoginFactory) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function (response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;

        vm.$onInit = function () {
            LoginFactory.validationUser();
        }

        if (vm.$state.$current.toString().indexOf('route-plan') !== -1) {
            // unwraping from all parent
            $("#pertama").removeClass('container');
        }

        $rootScope.$on('$stateChangeStart',
            function (event, toState, toParams, fromState, fromParams) {
                if (toState.name.indexOf('route-plan') !== -1) {
                    $("#pertama").removeClass('container');
                }
                else {
                    $("#pertama").addClass('container');
                }
            });

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

    }
})();
