(function () {
    'use strict';
    angular
        .module('stpApp')
        .factory('LoginFactory', LoginFactory);

    LoginFactory.$inject = ['$uibModal', 'Principal'];

    function LoginFactory($uibModal, Principal) {
        var vm = this;

        vm.service = {
            validationUser: validationUser,
        }

        function _initiateUserTracked(account) {
            if (!account) return;
            mixpanel.identify(account.id);
            var firstName = account.firstName ? account.firstName + ' ' : '';
            var lastName = account.lastName ? account.lastName : '';
            mixpanel.people.set({
                '$name': firstName + lastName,
                'Username': account.login,
                '$email': account.email
            });
        }

        function validationUser() {
            Principal.identity().then(function (account) {
                if (account) {

                    _initiateUserTracked(account);

                    // abaikan jika role user ROLE_ONE_TIME_USER
                    var roleOneTimeUser = account.authorities.some(function (role) {
                        return role === 'ROLE_ONE_TIME_USER'
                    });

                    if (roleOneTimeUser) {
                        invalid = false
                    }
                }
            })
        }

        return vm.service;

    }
})();
